import * as UndoStack from '../combinedReducers/undoRedoReducers/UndoStack'
import { AppState } from '../../app/models/appState'

export function undoStackWithContentAdded(
  state: AppState,
  boardId: string,
): AppState['app']['undoStack'] {
  const board = state.boards[boardId]
  const folders = state.folders[boardId]

  return UndoStack.withContentAdded(state.app.undoStack, boardId, {
    board,
    folders,
  })
}
