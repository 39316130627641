import { AppState } from '../../app/models/appState'
import { CurrentPlan, SubscriptionPlan } from '../../app/models'

export function setAvailableSubscriptionsReducer(
  state: AppState,
  { plans, current }: { plans: SubscriptionPlan[]; current: CurrentPlan },
): AppState {
  return {
    ...state,
    plans: {
      ...state.plans,
      status: 'loaded',
      data: { plans, current },
    },
  }
}

export function setAvailableSubscriptionsLoadingReducer(
  state: AppState,
  discountId?: string,
): AppState {
  return {
    ...state,
    plans: {
      ...state.plans,
      discountId,
      status: 'loading',
    },
  }
}

export function renewCurrentSubscriptionReducer(state: AppState): AppState {
  return {
    ...state,
    plans: {
      ...state.plans,
      data: {
        ...state.plans.data,
        current: state.plans.data.current
          ? {
              ...state.plans.data.current,
              scheduledCancel: '',
            }
          : null,
      },
    },
  }
}

export function cancelCurrentSubscriptionReducer(
  state: AppState,
  { scheduledCancel }: { scheduledCancel: Date },
): AppState {
  return {
    ...state,
    plans: {
      ...state.plans,
      data: {
        ...state.plans.data,
        current: state.plans.data.current
          ? {
              ...state.plans.data.current,
              scheduledCancel,
            }
          : null,
      },
    },
  }
}
