import { ButtonNew, PremiumCrownIcon } from 'boards-web-ui'
import { Trans, useTranslation } from 'react-i18next'
import React, { FC, useState } from 'react'
import { IconWrapper } from '@ui/components/IconWrapper'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { useToast } from '@hooks/useToast'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import {
  boardIndicator,
  contentNodeIndicator,
  getNodeId,
  newKey,
  rootFolderIndicator,
} from '@helpers/NodeIdGenerator'
import { useAuth } from '@hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import useBoardEvents from '@features/analytics/useBoardEvents'
import styles from './SharedBoardModalFooterSave.module.css'
import { copyBoardsApi } from '../../../../_firebase'

interface Props {
  joinedBoardId: string
  onCopyDone: () => void
}

const timeoutDelay = 500

export const SharedBoardModalFooterSave: FC<Props> = ({
  joinedBoardId,
  onCopyDone,
}) => {
  const { isFreePlanUser } = useReverseTrialContext()
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const { t } = useTranslation()
  const { openPaywall } = usePaywall()
  const navigate = useNavigate()
  const { user } = useAuth()

  const { appBoardCopiedEvent } = useBoardEvents()

  const handleSaveBoard = async () => {
    if (isFreePlanUser) {
      openPaywall({
        source: PaywallSourceEnum.SAVE_BOARD,
      })
      return
    }

    const key = newKey()
    const boardId = joinedBoardId.split('-').pop() || ''
    const newBoardId = getNodeId(boardId, key).replace(
      contentNodeIndicator,
      boardIndicator,
    )

    const currentUserId = user?.uid
    setIsLoading(true)

    try {
      await copyBoardsApi({ boards: [joinedBoardId], key })

      const navigateTo = `/${currentUserId}-${newBoardId}/${newBoardId.replace(
        boardIndicator,
        rootFolderIndicator,
      )}`

      appBoardCopiedEvent()
      onCopyDone()

      setTimeout(() => {
        navigate(navigateTo)
      }, timeoutDelay)
    } catch {
      toast(t('optimistic_ui_failed'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.Root}>
      <ButtonNew
        isLoading={isLoading}
        size={'medium'}
        startIcon={
          isFreePlanUser ? (
            <IconWrapper>
              <PremiumCrownIcon />
            </IconWrapper>
          ) : undefined
        }
        onClick={handleSaveBoard}
      >
        <Trans i18nKey="save_board_button" />
      </ButtonNew>
    </div>
  )
}
