import { useCallback } from 'react'
import { setMarketingInfo } from '../../../../_firebase/generalFunctions'
import { ProfilingMarketingStatesEnum } from '../models'

const useProfilingAPI = () => {
  const showMarketingForm = useCallback(() => {
    setMarketingInfo({
      status: ProfilingMarketingStatesEnum.PRESENTED,
    })
  }, [])

  const updateMarketingForm = useCallback(
    (answers: { [id: string]: string }) => {
      setMarketingInfo(answers)
    },
    [],
  )

  const saveMarketingForm = useCallback((answers: { [id: string]: string }) => {
    setMarketingInfo({
      status: ProfilingMarketingStatesEnum.COMPLETED,
      ...answers,
    })
  }, [])

  return {
    showMarketingForm,
    updateMarketingForm,
    saveMarketingForm,
  }
}

export default useProfilingAPI
