interface UndefinedKeysProps {
  [id: string]: unknown
}

export const removeUndefinedKeysFromObject = (data: UndefinedKeysProps) => {
  return Object.entries(data)
    .filter(([, value]) => value !== undefined)
    .reduce((obj, [key, value]) => {
      return { ...obj, [key]: value }
    }, {} as UndefinedKeysProps)
}
