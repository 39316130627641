import { useDispatch } from 'react-redux'
import useBulk from '@hooks/bulk/useBulk'
import { bulkActions } from '../../../../actions'
import { IBulkSelection } from '../models/nodes'
import { NodeType } from '../../../models'

const useBulkSelection = (): IBulkSelection => {
  const dispatch = useDispatch()
  const { bulk, resetBulkActions } = useBulk()
  const { contentId, nodes, action } = bulk

  const isSelected = (nodeId: string) =>
    Boolean(nodes.find(({ id }) => id === nodeId))

  const getSelectedTypes = Array.from(
    new Set(nodes.map(({ nodeType }) => nodeType)),
  )

  const isPageOnlySelected =
    getSelectedTypes.includes(NodeType.PAGE) && getSelectedTypes.length === 1

  const startSelection = ({
    boardId,
    folderId,
    shortcutId,
  }: {
    boardId: string
    folderId?: string
    shortcutId?: string
  }) => dispatch(bulkActions.setBulkAction({ boardId, folderId, shortcutId }))

  const toggleNodeSelection: IBulkSelection['toggleNodeSelection'] = (
    nodeId,
    type,
  ) => {
    if (nodes.length === 1 && isSelected(nodeId)) {
      resetBulkActions()
      return
    }

    dispatch(bulkActions.toggleNodeBulkAction(nodeId, type))
  }

  return {
    isInSelectionMode: contentId !== undefined,
    hasAction: action !== undefined,
    isSelected,
    startSelection,
    toggleNodeSelection,
    isPageOnlySelected,
  }
}

export default useBulkSelection
