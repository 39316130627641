import { useCallback } from 'react'
import paths from '@features/board/helpers/url'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useBoard from '@hooks/useBoard'
import { useParams } from 'react-router-dom'
import { Folder, Node } from '../app/models'
import { SHARED_FOLDERS } from '../constants/routes'

const useShortcut = () => {
  const { profile } = useProfileInformation()
  const { folder } = useBoard()
  const { pageId } = useParams()

  const getShortcut = useCallback(
    (node: Node | Folder | undefined) => {
      if (!profile.shortcut) {
        return null
      }

      let shortcut

      // user in page editor route
      if (pageId) {
        const nodes = folder?.content
        const pageNode = nodes?.find((n) => n.id === pageId)
        shortcut = pageNode?.shortcut
      } else {
        shortcut = node?.shortcut || folder?.shortcut
      }

      if (shortcut) {
        return `${paths.shortcutDomain}${SHARED_FOLDERS.oldRoot}/${profile.shortcut}.${shortcut}`
      }

      return null
    },
    [profile.shortcut, pageId, folder?.content, folder?.shortcut],
  )

  return { getShortcut }
}

export default useShortcut
