import { AppState } from '../../app/models/appState'
import {
  CONTENT_ACTION_TYPE,
  NODE_ACTION_TYPE,
  PAGE_ACTION_TYPE,
  SET_PERSIST_USER,
  UPDATES_ACTION_TYPE,
} from '../../actions'
import {
  setActionInProgressReducer,
  setPropertiesReducer,
} from '../general/genralRedusers'
import {
  addContentReducer,
  addPageReducer,
  deleteNodesReducer,
  moveNodesReducer,
  setFormIntegrationReducer,
  setUserContentSettingsReducer,
} from './combinedReducers'
import { TPageActions } from '../../actions/page'
import { TContentActions } from '../../actions/content'
import { copyTemplateReducer } from './nodeReducers/copyTemplateReducer'
import { undoStackWithContentAdded } from '../general/undoStackWithContentAdded'
import { TNodeActions } from '../../actions/node'
import { copyNodesReducer } from './nodeReducers/copyNodesReducer'
import {
  deleteUpdatesMessageReducer,
  receiveReadUnReadMessagesReducer,
  receiveUpdatesMessagesReducer,
  removeUnPostedUpdatesMessageReducer,
  saveUnPostedUpdatesMessageReducer,
} from './updatesRedusers/updatesRedusers'
import { TUpdatesActions } from '../../actions/updates'
import { TSetPersistUserActions } from '../../actions/persistUser'

export type CombinedActions =
  | TPageActions
  | TContentActions
  | TNodeActions
  | TUpdatesActions
  | TSetPersistUserActions

// TODO https://heybliss.atlassian.net/browse/BLISS-10770
export function combinedReducers(
  state: AppState,
  action: CombinedActions,
): AppState | undefined {
  switch (action.type) {
    case PAGE_ACTION_TYPE.ADD_PAGE: {
      return setActionInProgressReducer(
        addPageReducer(
          state,
          action.payload.board,
          action.payload.folder,
          action.payload.key,
          action.payload.properties,
        ),
      )
    }

    case CONTENT_ACTION_TYPE.ADD_CONTENT: {
      return setActionInProgressReducer(
        addContentReducer(
          state,
          action.board,
          action.folder,
          action.properties,
          action.key,
        ),
      )
    }

    case CONTENT_ACTION_TYPE.SET_USER_CONTENT_SETTINGS:
      return setUserContentSettingsReducer(state, action.settings)

    case CONTENT_ACTION_TYPE.COPY_TEMPLATE: {
      return copyTemplateReducer(state, action, undoStackWithContentAdded)
    }

    case CONTENT_ACTION_TYPE.REVERT_COPY_TEMPLATE: {
      return deleteNodesReducer(state, action.boardId, action.folderId, [
        action.pageId,
      ])
    }

    case NODE_ACTION_TYPE.DELETE_NODES:
      return setActionInProgressReducer(
        deleteNodesReducer(state, action.board, action?.folder, action?.nodes),
      )

    case NODE_ACTION_TYPE.COPY_NODES:
      return copyNodesReducer(state, action, undoStackWithContentAdded)

    case NODE_ACTION_TYPE.SET_FORM_INTEGRATION_STATE: {
      return setFormIntegrationReducer(
        state,
        action.board,
        action.folder,
        action.node,
        action.integrationState,
      )
    }

    case NODE_ACTION_TYPE.SET_PROPERTIES:
      return setActionInProgressReducer(
        setPropertiesReducer(
          state,
          action.board,
          action.folder,
          action?.node,
          action.properties as unknown as { [key: string]: unknown },
        ),
      )

    case NODE_ACTION_TYPE.MOVE_NODES:
      return setActionInProgressReducer(
        moveNodesReducer(
          state,
          action.board,
          action?.folder,
          action.nodes,
          action.fromFolder,
          action.before,
        ),
      )

    case UPDATES_ACTION_TYPE.RECEIVED_UPDATES_MESSAGES:
      return receiveUpdatesMessagesReducer(state, action)

    case UPDATES_ACTION_TYPE.RECEIVED_READ_UNREAD_MESSAGES:
      return receiveReadUnReadMessagesReducer(state, action)

    case UPDATES_ACTION_TYPE.DELETE_UPDATES_MESSAGE:
      return deleteUpdatesMessageReducer(state, action.boardId, action.mid)

    case UPDATES_ACTION_TYPE.SAVE_UN_POST_UPDATES_MESSAGE:
      return saveUnPostedUpdatesMessageReducer(state, action)

    case UPDATES_ACTION_TYPE.REMOVE_UN_POST_UPDATES_MESSAGE:
      return removeUnPostedUpdatesMessageReducer(state, action)

    case SET_PERSIST_USER.SET_PERSIST_USER:
      return {
        ...state,
        persistUser: action.isPersist,
      }

    default:
      return undefined
  }
}
