const defaultTheme = {
  form: {
    inputLabel: {
      cursor: 'default',
      font: {
        size: 14,
      },
      color: '999999',
    },
  },
}

export default defaultTheme
