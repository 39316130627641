import React from 'react'

import useBoard from '@hooks/useBoard'
import clsx from 'clsx'
import { BoardListItem } from '@features/boardsMenu/components/BoardListItem'
import styles from './SelectBoardItem.module.css'

interface Props {
  emoji?: string
  title?: string
  isPageBoard?: boolean
  isShared: boolean
  disabled: boolean
  onClick: () => void
  isReadMode?: boolean
  boardId?: string
}
const SelectBoardItem = ({
  emoji,
  title,
  isShared,
  disabled,
  onClick,
  isPageBoard,
  isReadMode,
  boardId,
}: Props) => {
  const {
    isReadOnly,
    isSponsored,
    isShared: isSharedSelectorState,
    totalBoardMembersCount,
    roleText,
  } = useBoard({ boardId })

  const isDisabled = (isReadOnly && isReadMode) || disabled

  return (
    <BoardListItem
      isPageBoard={isPageBoard}
      icon={emoji}
      isSponsored={isSponsored}
      title={title}
      hasSharedIcon={(isShared || isSharedSelectorState) && !isSponsored}
      countBoardMembers={totalBoardMembersCount}
      roleText={roleText}
      className={clsx(styles.Root, isDisabled && styles.Disabled)}
      isContentPicker
      onClick={isDisabled ? undefined : onClick}
    />
  )
}

export default SelectBoardItem
