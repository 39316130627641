import { FC, ReactElement } from 'react'
import { Trans } from 'react-i18next'
import { format } from 'date-fns'

import styles from './PriceInfo.module.css'

interface Props {
  planTitle: ReactElement | string
  planPrice?: ReactElement | string
  scheduledCancel?: string | Date
}
const PriceInfo: FC<Props> = ({ planTitle, planPrice, scheduledCancel }) => {
  return (
    <div className={styles.Root}>
      <div className={styles.PlanName}>{planTitle}</div>
      <div className={styles.PlanPrice}>{planPrice}</div>

      {scheduledCancel && (
        <div className={styles.PlanExpiresMessage}>
          <Trans
            i18nKey={'cancel_popup_expiry_date'}
            values={{
              date: format(new Date(scheduledCancel), 'yyyy-MM-dd'),
            }}
          />
        </div>
      )}
    </div>
  )
}

export default PriceInfo
