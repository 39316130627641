export const roundDigitWithSuffix = (
  initialDigit: number,
): {
  digit: number
  suffix: string
} => {
  if (initialDigit > 0 && initialDigit < 999) {
    return {
      digit: initialDigit,
      suffix: '',
    }
  }

  if (initialDigit > 1000 && initialDigit < 9999) {
    return {
      digit: Math.ceil((initialDigit / 1000) * 10) / 10,
      suffix: 'K',
    }
  }

  if (initialDigit >= 10000 && initialDigit < 999999) {
    return {
      digit: Math.round(initialDigit / 1000),
      suffix: 'K',
    }
  }

  if (initialDigit >= 1000000 && initialDigit < 9999999) {
    return {
      digit: Math.round((initialDigit / 1000000) * 10) / 10,
      suffix: 'M',
    }
  }

  if (initialDigit >= 10000000) {
    return {
      digit: Math.round(initialDigit / 1000000),
      suffix: 'M',
    }
  }

  return {
    digit: initialDigit,
    suffix: '',
  }
}
