import { useQuery } from 'react-query'
import { getTemplates } from '_firebase'
import { configurationObjectFromEnv } from 'utils/configurationObjectFromEnv'
import { ENV_KEYS } from 'utils/envKeys'
import transformTemplateCategory from '@features/templateGallery/helpers/templateCategoryTransformer'

const fetchTemplates = async () => {
  const templates = await getTemplates()
  if (templates) {
    const templateCategories = await Promise.all(
      templates.payload.categories.map(transformTemplateCategory),
    )

    return {
      headers: templates?.headers,
      templateCategories,
    }
  }

  throw new Error('Failed to fetch templates')
}
interface Props {
  onError?: () => void
}
const useTemplates = ({ onError }: Props) => {
  const { nodeEnv } = configurationObjectFromEnv<{
    nodeEnv: 'development' | 'production'
  }>({
    nodeEnv: ENV_KEYS.NODE_ENV,
  })
  const cacheTime = nodeEnv === 'development' ? 3 * 60 * 1000 : 60 * 60 * 1000

  return useQuery({
    queryKey: ['templates'],
    staleTime: cacheTime,
    cacheTime,
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: fetchTemplates,
    onError,
  })
}

export default useTemplates
