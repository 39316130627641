import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useRouterParams from '../../../../hooks/useRouterParams'
import { getInvite } from '../../../../selectors'
import {
  addBoardPublicKey,
  generateBoardPublicKey,
  updateBoardPublicKey,
} from '../../../../_firebase'

interface GenerateKeyProps {
  defaultKey: string
}

const useSharePublicLink = () => {
  const { boardId, uid, bid } = useRouterParams()
  const invite = useSelectorFactory(getInvite, boardId)
  const publicKey = invite?.key || null
  const publicKeyEnabled = invite?.enabled || false

  const createOrEnableInviteLink = async () => {
    if (invite) {
      await toogleInviteLink()
      return
    }
    const data = await generateBoardPublicKey(uid, bid)

    const { defaultKey } = data as GenerateKeyProps

    addBoardPublicKey(uid, bid, defaultKey)
  }

  const toogleInviteLink = async () =>
    updateBoardPublicKey(uid, bid, !publicKeyEnabled)

  return {
    publicKey,
    publicKeyEnabled,
    createOrEnableInviteLink,
    toogleInviteLink,
  } as const
}

export default useSharePublicLink
