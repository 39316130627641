import { Dispatch } from 'react'
import { Action, AnyAction, Store } from 'redux'
import { History } from 'history'

import {
  actionActions,
  BOARD_ACTION_TYPE,
  bulkActions,
  COMMON_ACTION_TYPE,
  CONTENT_ACTION_TYPE,
  firebaseEventsAsyncActions,
  FOLDER_ACTION_TYPE,
  MEMBERS_ACTION_TYPE,
  NODE_ACTION_TYPE,
  PERMISSION_ACTION_TYPE,
  PROFILE_INFO_ACTION_TYPE,
  toastActions,
  UNDO_REDO_ACTION_TYPE,
  UPDATES_ACTION_TYPE,
  USER_ACTION_TYPE,
} from '../../actions'

import {
  activateBoard,
  addContent,
  addFolder,
  addMembers,
  createBoard,
  deleteBoard,
  deleteNodes,
  deleteUpdatesMessage,
  joinSharedBoard,
  markUpdatesMessagesAsRead,
  moveNodes,
  postUpdatesMessage,
  redo,
  removeMembers,
  setPermission,
  setProfileInformation,
  setProperties,
  undo,
} from '../contentAPI'
import { selectBoards } from '../../selectors'
import sanitizeBoards from './sanitizeBoards'
import i18n from '../../translations/i18n'
import {
  sendMobileAppInvitationEmail,
  setUIState,
  setUserEmailPreferences,
  setUserProperties,
  startFreeTrial,
} from '../generalFunctions'
import { Board, Folder } from '../../app/models'
import { AppState, IBulkState } from '../../app/models/appState'
import { copyNodesApiCall } from '../nodeActions'

// TODO create action types map
// eslint-disable-next-line
const actions: { [key: string]: (...props: any) => void } = {
  [BOARD_ACTION_TYPE.CREATE_BOARD]: createBoard,
  [BOARD_ACTION_TYPE.ACTIVATE_BOARD]: activateBoard,
  [BOARD_ACTION_TYPE.DELETE_BOARD]: deleteBoard,
  [BOARD_ACTION_TYPE.LEAVE_BOARD]: removeMembers,
  [BOARD_ACTION_TYPE.JOIN_SHARED_BOARD]: joinSharedBoard,

  [FOLDER_ACTION_TYPE.ADD_FOLDER]: addFolder,
  [CONTENT_ACTION_TYPE.ADD_CONTENT]: addContent,

  [NODE_ACTION_TYPE.SET_PROPERTIES]: setProperties,
  [NODE_ACTION_TYPE.DELETE_NODES]: deleteNodes,
  [NODE_ACTION_TYPE.COPY_NODES]: copyNodesApiCall,
  [NODE_ACTION_TYPE.MOVE_NODES]: moveNodes,

  [UNDO_REDO_ACTION_TYPE.UNDO]: undo,
  [UNDO_REDO_ACTION_TYPE.REDO]: redo,

  [UPDATES_ACTION_TYPE.POST_UPDATES_MESSAGE]: postUpdatesMessage,
  [UPDATES_ACTION_TYPE.DELETE_UPDATES_MESSAGE]: deleteUpdatesMessage,
  [UPDATES_ACTION_TYPE.MARK_UPDATES_MESSAGES_AS_READ]:
    markUpdatesMessagesAsRead,

  [PROFILE_INFO_ACTION_TYPE.UPDATE_PROFILE_INFO]: setProfileInformation,
  [PROFILE_INFO_ACTION_TYPE.SET_USER_PROFILE_NAME]: setUserProperties,

  [COMMON_ACTION_TYPE.SET_UI_STATE]: setUIState,
  [COMMON_ACTION_TYPE.SEND_MOBILE_APP_INVITATION_EMAIL]:
    sendMobileAppInvitationEmail,

  [USER_ACTION_TYPE.SET_USER_EMAIL_PREFERENCES]: setUserEmailPreferences,
  [USER_ACTION_TYPE.START_FREE_TRIAL]: startFreeTrial,

  [MEMBERS_ACTION_TYPE.ADD_MEMBERS]: addMembers,
  [MEMBERS_ACTION_TYPE.REMOVE_MEMBERS]: removeMembers,

  [PERMISSION_ACTION_TYPE.UPDATE_PERMISSION]: setPermission,
}

const handleRedirectAfterFail = (
  action: Action,
  previousState: Store,
  history: History,
) => {
  switch (action.type) {
    case BOARD_ACTION_TYPE.CREATE_BOARD:
      {
        const { order } = selectBoards(previousState as unknown as AppState)
        const firstBoardId = order[0]
        if (firstBoardId) {
          history.push(`/${firstBoardId}`)
        }
      }
      break
    case FOLDER_ACTION_TYPE.ADD_FOLDER: {
      const { board, folder } = action as AnyAction
      history.push(`/${board}/${folder}`)
      break
    }

    // no default
  }
}

function createFirebaseMiddleware(history: History) {
  return ({
      dispatch,
      getState,
    }: {
      dispatch: Dispatch<unknown>
      getState: () => Store<unknown>
    }) =>
    (next: (action: Action) => unknown) =>
    async (action: Action) => {
      const previousState = getState()
      const returnValue = next(action)

      if (!Object.keys(actions).includes(action.type)) {
        return returnValue
      }

      try {
        const contentFunction = actions[action.type]
        return await contentFunction(action)
      } catch (error) {
        handleRedirectAfterFail(action, previousState, history)
        // TODO implement Application Store Interface
        sanitizeBoards(
          previousState as unknown as {
            boards: { [boardId: string]: Board }
            folders: { [boardId: string]: Folder }
          },
        )
        dispatch(actionActions.revertAction(previousState))

        if (
          (previousState as unknown as { app: { bulk: IBulkState } })?.app?.bulk
            .action === 'delete'
        ) {
          dispatch(bulkActions.resetBulkAction())
        }

        dispatch(
          firebaseEventsAsyncActions.logEvent('app_server_error', {
            error_code: error,
          }),
        )

        dispatch(toastActions.openToastMessage(i18n.t('optimistic_ui_failed')))
        setTimeout(() => {
          dispatch(toastActions.closeToastMessage())
        }, 2500)
      } finally {
        dispatch(actionActions.stopActionInProgress())
      }

      return returnValue
    }
}

export default createFirebaseMiddleware
