import { useEffect, useRef } from 'react'
import { NODE_LINK_TYPES, useDialog } from 'boards-web-ui'

import { useSelector } from 'react-redux'
import AddButton from '../components/AddButton'
import { IUseAddNode } from '../models/IUseAddNode'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import { AppState } from '../../../models/appState'

const useAddButton: IUseAddNode = (save) => {
  const { open } = useDialog()
  const { hideButtonsHint } = useProfileInformation()
  const { buttonType: type, buttonGeoData } = useSelector(
    (state: AppState) => state.app.pageEditor,
  )
  const typeRef = useRef<NODE_LINK_TYPES | null | undefined>(null)
  const geoDataRef = useRef<
    { countryCode?: string; dialCode?: string } | undefined | null
  >(null)

  useEffect(() => {
    typeRef.current = type
  }, [type])

  useEffect(() => {
    geoDataRef.current = buttonGeoData
  }, [buttonGeoData])

  const addTextNode = () => {
    hideButtonsHint()

    open(<AddButton onSave={save} />, {
      fullScreen: true,
      blankDialog: true,
    })
  }

  return addTextNode
}

export default useAddButton
