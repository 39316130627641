import { UserContentSettings } from '@models/UserContentSettings'
import { AppState } from '../../../app/models/appState'
import { MessageProperties, UpdatesMessage } from '../../../app/models'

export function receiveUpdatesMessagesReducer(
  state: AppState,
  { boardId, messages }: { boardId: string; messages: UpdatesMessage[] },
): AppState {
  return {
    ...state,
    updates: {
      ...state.updates,
      [boardId]: messages,
    },
  }
}

export function receiveReadUnReadMessagesReducer(
  state: AppState,
  { boardId, data }: { boardId: string; data: UserContentSettings },
): AppState {
  return {
    ...state,
    updatesReadUnRead: {
      ...state.updatesReadUnRead,
      [boardId]: data,
    },
  }
}

export function deleteUpdatesMessageReducer(
  state: AppState,
  boardId: string,
  mid: string,
): AppState {
  return {
    ...state,
    updates: {
      ...state.updates,
      [boardId]: state.updates[boardId].filter(
        (_message) => _message.id !== mid,
      ),
    },
  }
}

export function saveUnPostedUpdatesMessageReducer(
  state: AppState,
  {
    postMessageData,
  }: {
    postMessageData: {
      board: string
      properties: MessageProperties
    }
  },
): AppState {
  return {
    ...state,
    updatesUnPostMessages: {
      ...state.updatesUnPostMessages,
      [postMessageData.board]: {
        ...postMessageData.properties,
      },
    },
  }
}

export function removeUnPostedUpdatesMessageReducer(
  state: AppState,
  { board }: { board: string },
): AppState {
  const { updatesUnPostMessages } = state

  delete updatesUnPostMessages[board]

  return {
    ...state,
    updatesUnPostMessages,
  }
}
