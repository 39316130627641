import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import MenuTitle from './MenuTitle'

import { BoardMenuItem } from '../models/boardMenuItem'
import useFirebaseListeners from '../../../../_firebase/hooks/useFirebaseListeners'
import useOnlyFirstChange from '../../../../hooks/useOnlyFirstChange'

type Props = PropsWithChildren<{
  inactive?: boolean
  isRoot?: boolean
  level: number
  menu: BoardMenuItem
  shouldExpandChildren?: boolean
  initialStateCheck?: boolean
  className?: string
  classNameTitle?: string
  isFreeUserMenu?: boolean
}>

const MenuItem: FC<Props> = ({
  inactive,
  menu,
  initialStateCheck,
  level,
  shouldExpandChildren,
  children,
  isRoot = false,
  className,
  classNameTitle,
  isFreeUserMenu,
}) => {
  const [isExpanded, setIsExpanded] = useState(menu.isExpanded)
  const { startBoardFoldersListener, startBoardPermissionsListener } =
    useFirebaseListeners()

  const onToggle = () => {
    setIsExpanded((state) => !state)
    const [uid, bid] = menu.boardId.split('-')
    startBoardFoldersListener(uid, bid)

    // TODO: fix ASAP
    if (
      ![
        'y6FgvMuICbUNGN7dPMw7cG7EoDY$',
        'NI8rhbvG0sJI41IHjAHGWg1sSFV$',
        'wbs5eJFpSzRzi0OtWCm1gVFwDht$',
        'KJuCDTwV31PfrmVj817GY18H71N$',
        '6brJ7EZrwNtlfW6rhPlL5wi0vcf$',
      ].includes(bid)
    ) {
      startBoardPermissionsListener(uid, bid)
    }
  }

  useOnlyFirstChange(() => {
    if (
      initialStateCheck &&
      menu.hasSelectedChildren &&
      !menu.parent &&
      menu.isExpanded &&
      !isExpanded
    ) {
      setIsExpanded(true)
    }
  }, [menu.isExpanded])

  useEffect(() => {
    if (!isExpanded && menu.isExpanded && shouldExpandChildren) {
      setIsExpanded(true)
    }
    // no need to add more dependency to avoid extra trigger
    // eslint-disable-next-line
  }, [menu.isExpanded])

  return (
    <>
      <MenuTitle
        inactive={inactive}
        level={level}
        nodeId={menu.id}
        boardId={menu.boardId}
        icon={menu.icon}
        title={menu.title}
        isExpanded={isExpanded}
        isSponsored={isRoot && menu.isSponsored}
        isSelected={
          menu.isSelected ||
          (!isExpanded && !menu.isSelected && !!menu.hasSelectedChildren)
        }
        isDisabled={!menu.hasSubMenus}
        hasSharedIcon={menu.hasSharedIcon}
        onClick={menu.onClick}
        onToggle={onToggle}
        className={className}
        classNameTitle={classNameTitle}
        isPageBoard={menu.isPageBoard}
        countBoardMembers={menu.countBoardMembers}
        roleText={menu.roleText}
        isFreeUserMenu={isFreeUserMenu}
      >
        {children}
      </MenuTitle>

      {isExpanded &&
        menu.subMenus &&
        menu.subMenus.length > 0 &&
        menu.subMenus.map((subMenu) => (
          <MenuItem
            key={subMenu.id}
            className={className}
            classNameTitle={classNameTitle}
            level={level + 1}
            menu={subMenu}
            shouldExpandChildren={isExpanded}
          />
        ))}
    </>
  )
}

export default React.memo(MenuItem)
