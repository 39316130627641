import React, { useCallback, useState } from 'react'
import { ButtonIcon, PagesBoardIcon } from 'boards-web-ui'

import { TooltipPositions } from '@ui/models/tooltip'
import { IconWrapper } from '@ui/components/IconWrapper'
import { DropDown } from '@ui/components/DropDown'
import DisableStateTooltip from '@ui/components/DisableStateTooltip'
import EmojiPicker from '../../../../components/EmojiPicker'

import styles from './ContentIcon.module.css'

type Props = {
  icon?: string
  tooltip: string
  onChange: (icon: string) => void
  className?: string
  readOnly?: boolean
  isPageBoard?: boolean
  closeOnOutsideClick?: boolean
  tooltipPosition?: TooltipPositions
}

const ContentIcon: React.FC<Props> = ({
  icon,
  tooltip,
  className,
  onChange,
  readOnly,
  isPageBoard,
  closeOnOutsideClick,
  tooltipPosition,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleChange = (newIcon: string) => {
    setIsOpen(false)

    if (onChange) {
      onChange(newIcon)
    }
  }

  const handleClickOutside = useCallback(() => {
    if (closeOnOutsideClick) {
      setIsOpen(false)
    }
  }, [closeOnOutsideClick])

  return (
    <DropDown
      isOpen={isOpen}
      onToggle={setIsOpen}
      content={
        <EmojiPicker
          onClickEmoji={handleChange}
          onClickOutside={handleClickOutside}
        />
      }
      className={styles.Margin}
      disabled={!!readOnly || isPageBoard}
    >
      <DisableStateTooltip
        isOpen={isOpen ? false : undefined}
        data={tooltip}
        disabled={isOpen || !!readOnly || !!isPageBoard}
        position={tooltipPosition}
      >
        <ButtonIcon
          round
          variant="gray"
          size={38}
          isActive={isOpen}
          className={className}
          disabled={!!readOnly || isPageBoard}
          type={'button'}
        >
          <IconWrapper size={24}>
            {isPageBoard ? <PagesBoardIcon /> : icon}
          </IconWrapper>
        </ButtonIcon>
      </DisableStateTooltip>
    </DropDown>
  )
}

export default React.memo(ContentIcon)
