import { IGeolocationData, NODE_LINK_TYPES } from 'boards-web-ui'
import { Feature } from '@features/profile/hooks/useProfileInformation'
import { AppState } from '../../app/models/appState'
import { BoardInviteLink } from '../../app/models/boardInviteLink'
import { NodeType } from '../../app/models'

export function setGeoDataReducer(
  state: AppState,
  data: Partial<IGeolocationData> | undefined,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      geoData: data,
    },
  }
}

export function setFormErrorsReducer(
  state: AppState,
  formErrors: { [key: string]: string },
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      pageEditor: {
        ...state.app.pageEditor,
        formErrors,
      },
    },
  }
}

export function stateSetLoadingReducer(
  state: AppState,
  loading: boolean,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      loading,
    },
  }
}

export function setInviteReducer(
  state: AppState,
  { boardId, invite }: { boardId: string; invite: BoardInviteLink | null },
): AppState {
  return {
    ...state,
    invites: {
      ...state.invites,
      [boardId]: invite,
    },
  }
}

export function setUiStateReducer(
  state: AppState,
  { property, value }: { property: Feature; value: string | boolean | number },
) {
  return {
    ...state,
    profileInfo: {
      ...state.profileInfo,
      uiState: {
        ...state.profileInfo.uiState,
        [property]: value,
      },
    },
  }
}

export function setButtonErrorReducer(
  state: AppState,
  buttonError?: string | undefined,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      pageEditor: {
        ...state.app.pageEditor,
        buttonError,
      },
    },
  }
}

export function setButtonTypeReducer(
  state: AppState,
  buttonType?: NODE_LINK_TYPES,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      pageEditor: {
        ...state.app.pageEditor,
        buttonType,
      },
    },
  }
}
export function setButtonGeoDataReducer(
  state: AppState,
  geoData?: {
    countryCode?: string
    dialCode?: string
  },
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      pageEditor: {
        ...state.app.pageEditor,
        buttonGeoData: geoData,
      },
    },
  }
}

export function setIsGoogleSheetsUrlValidReducer(
  state: AppState,
  isUrlValid: boolean,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      pageEditor: {
        ...state.app.pageEditor,
        isGoogleSheetsUrlValid: isUrlValid,
      },
    },
  }
}

export function videoErrorReducer(
  state: AppState,
  hasError: boolean,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        nodeVideo: {
          ...state.app.nodeEditor.nodeVideo,
          hasError,
        },
      },
    },
  }
}

export function videoStreamingUrlReducer(
  state: AppState,
  url: string,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        nodeVideo: {
          ...state.app.nodeEditor.nodeVideo,
          videoUrl: url,
        },
      },
    },
  }
}

export function videoStreamingIdReducer(state: AppState, id: string): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        nodeVideo: {
          ...state.app.nodeEditor.nodeVideo,
          videoId: id,
        },
      },
    },
  }
}

export function addUploadReducer(state: AppState, id: string): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      uploading: [...state.app.uploading, id],
    },
  }
}

export function removeUploadReducer(state: AppState, id: string): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      uploading: state.app.uploading.filter((u) => u !== id),
    },
  }
}

export function setBoardsOrderReducer(
  state: AppState,
  order: Array<string>,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      content: {
        order,
      },
    },
  }
}

export function setBulkActionReducer(
  state: AppState,
  boardId: string,
  folderId?: string,
  shortcutId?: string,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      bulk: {
        ...state.app.bulk,
        contentId: boardId,
        folderId,
        shortcutId,
      },
    },
  }
}

export function setBulkActionTypeReducer(
  state: AppState,
  action: 'delete',
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      bulk: {
        ...state.app.bulk,
        action,
      },
    },
  }
}

export function toggleNodeBulkActionReducer(
  state: AppState,
  nodeId: string,
  nodeType: NodeType,
): AppState {
  const { nodes } = state.app.bulk
  const isNodeInBulk = Boolean(nodes.find(({ id }) => id === nodeId))

  const updatedNodes = isNodeInBulk
    ? nodes.filter(({ id }) => id !== nodeId)
    : [...nodes, { id: nodeId, nodeType }]

  return {
    ...state,
    app: {
      ...state.app,
      bulk: {
        ...state.app.bulk,
        nodes: updatedNodes,
      },
    },
  }
}

export function resetBulkActionReducer(state: AppState): AppState {
  if (state.app.bulk.contentId === undefined) {
    return state
  }
  return {
    ...state,
    app: {
      ...state.app,
      bulk: {
        action: undefined,
        contentId: undefined,
        folderId: undefined,
        type: undefined,
        nodes: [],
      },
    },
  }
}

export function openToastMessageReducer(
  state: AppState,
  action: {
    message: string
    global?: boolean
    showIcon?: boolean
    customIcon?: string | boolean
    onClose?: () => void
  },
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      toast: {
        msg: action.message,
        global: action.global,
        showIcon: action.showIcon,
        customIcon: action.customIcon,
        onClose: action.onClose,
      },
    },
  }
}

export function closeToastMessageReducer(state: AppState): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      toast: {
        msg: undefined,
        global: true,
        customIcon: undefined,
        onClose: undefined,
      },
    },
  }
}
