import { Trans } from 'react-i18next'

import styles from './VideoError.module.css'

const VideoError = () => (
  <p className={styles.Root}>
    <Trans i18nKey="add_youtube_vimeo_link_error" />
  </p>
)

export default VideoError
