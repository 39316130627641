import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useClipboard } from '@hooks/useClipboard'
import { useToast } from '@hooks/useToast'
import Button from '../../../../elements/Button'
import useShareEvents from '../../analytics/useShareEvents'
import paths from '../../board/helpers/url'
import { SHARED_FOLDERS } from '../../../../constants/routes'

import styles from './CopyLink.module.css'
import { useProfileInformation } from '../../profile/hooks/useProfileInformation'

interface Props {
  code: string | null
  active: boolean
}
const CopyLink = ({ code, active }: Props) => {
  const toast = useToast()
  const { t } = useTranslation()
  const { copyText } = useClipboard()
  const { copyInviteLinkEvent } = useShareEvents()
  const [copyEnabled, setCopyEnabled] = useState(true)

  const { profile } = useProfileInformation()

  const url = `${paths.shortcutDomain}${SHARED_FOLDERS.newRoot}/${
    profile.shortcut || 'user'
  }.${code || ''}`

  const copyToClipboard = async () => {
    await copyText(url)
    copyInviteLinkEvent()
    toast(t('message_link_copied'))

    setCopyEnabled(false)
    setTimeout(() => {
      setCopyEnabled(true)
    }, 2500)
  }

  const classes = [styles.Root]
  if (!active) {
    classes.push(styles.Disabled)
  }
  if (active && !code) {
    classes.push(styles.Loading)
  }

  return (
    <div className={classes.join(' ')}>
      <div className={styles.Link}>
        {url}
        {!code && (
          <span className={styles.BallPulse}>
            <div></div>
            <div></div>
            <div></div>
          </span>
        )}
      </div>
      <div className={styles.CopyLinkBtnWrapper}>
        <Button
          color="secondary"
          onClick={copyToClipboard}
          fullWidth
          disabled={!active || !copyEnabled}
        >
          <Trans i18nKey="action_copy_link" />
        </Button>
      </div>
    </div>
  )
}

export default CopyLink
