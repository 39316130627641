import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { calculateAspectRatioFit, VideoPlayer } from 'boards-web-ui'
import useNodeEvents, { ScreenNames } from '@features/analytics/useNodeEvents'

import { Node } from '../../../models'

import useFolder from '../../../../hooks/useFolder'

import styles from './VideoStreaming.module.css'

const DEFAULT_VIDEO_WIDTH = 675
const DEFAULT_VIDEO_HEIGHT = 380
const MAX_VIDEO_WIDTH = 590
const MAX_VIDEO_HEIGHT = 360

interface Props {
  node: Node
  showDescriptionLabel?: boolean
}
const VideoStreamingPlayer = ({ node, showDescriptionLabel }: Props) => {
  const { t } = useTranslation()
  const { videoPlayEvent } = useNodeEvents()
  const [width, setWidth] = useState(node.width)
  const [height, setHeight] = useState(node.height)

  /**
   * After saving meta properties comes undefined on first snaphost. This will
   * prevent update width and height values to undefined.
   */
  useEffect(() => {
    if (node.width) setWidth(node.width)
    if (node.height) setHeight(node.height)
  }, [node.width, node.height])

  const boxSize = calculateAspectRatioFit(
    width || DEFAULT_VIDEO_WIDTH,
    height || DEFAULT_VIDEO_HEIGHT,
    MAX_VIDEO_WIDTH,
    MAX_VIDEO_HEIGHT,
  )

  const onPlay = () => videoPlayEvent(node, ScreenNames.CONTENT_EDIT)

  return (
    <div className={styles.Root} style={{ width: boxSize.width }}>
      <VideoPlayer
        src={node.text}
        thumbnail={node.thumbnail}
        width={width || DEFAULT_VIDEO_WIDTH}
        height={height || DEFAULT_VIDEO_HEIGHT}
        isProcessing={node.isProcessing}
        processingLabel={t('label_processing_video')}
        descriptionLabel={
          showDescriptionLabel ? t('video_upload_processing_label') : undefined
        }
        onPlay={onPlay}
      />
    </div>
  )
}

interface VideoStreamingProps {
  boardId: string
  folderId?: string
  nodeId: string
  showDescriptionLabel?: boolean
  node?: Node | undefined
}
const VideoStreaming = ({
  boardId,
  folderId,
  nodeId,
  showDescriptionLabel,
  node: newNode,
}: VideoStreamingProps) => {
  const { folder } = useFolder(boardId, folderId)
  const node = newNode || folder?.content?.find(({ id }) => id === nodeId)

  if (!node) return null

  return (
    <VideoStreamingPlayer
      node={node}
      showDescriptionLabel={showDescriptionLabel}
    />
  )
}

export default VideoStreaming
