import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { ICopyNodesParams } from '../_firebase/nodeActions'
import { FormIntegrationStatusData } from '../_firebase'
import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  REMOVE_NODE = 'REMOVE_NODE',
  ADD_TEXT_NODE = 'ADD_TEXT_NODE',
  COPY_NODES = 'COPY_NODES',
  MOVE_NODES = 'MOVE_NODES',
  DELETE_NODES = 'DELETE_NODES',
  SET_FORM_INTEGRATION_STATE = 'SET_FORM_INTEGRATION_STATE',
  SET_PROPERTIES = 'SET_PROPERTIES',
}

const copyNodesAction = ({
  targetBoardId,
  targetFolderId,
  nodes,
  key,
  fromParams,
}: ICopyNodesParams): ICopyNodesParams & { type: ACTION_TYPE } => ({
  type: ACTION_TYPE.COPY_NODES,
  targetBoardId,
  targetFolderId,
  nodes,
  key,
  fromParams,
})

const moveNodesAction = ({
  targetBoardId,
  targetFolderId,
  nodes,
  sourceFolderId,
  beforeNodeId,
}: {
  targetBoardId: string
  targetFolderId?: string
  nodes: Array<string>
  sourceFolderId?: string
  beforeNodeId?: string
}) => ({
  type: ACTION_TYPE.MOVE_NODES,
  board: targetBoardId,
  folder: targetFolderId,
  nodes,
  fromFolder: sourceFolderId,
  before: beforeNodeId,
})

const deleteNodes = (
  board: string,
  folder?: string,
  nodes?: Array<string>,
) => ({
  type: ACTION_TYPE.DELETE_NODES,
  board,
  folder,
  nodes,
})

const addTextNode = (
  boardId: string,
  parentId: string,
  title: string,
  text: string,
) => ({
  type: ACTION_TYPE.ADD_TEXT_NODE,
  boardId,
  parentId,
  title,
  text,
})

const removeNode = (boardId: string, nodeId: string) => ({
  type: ACTION_TYPE.REMOVE_NODE,
  boardId,
  nodeId,
})

const setFormIntegrationState = (
  board: string,
  folder: string,
  node: string = '',
  integrationState?: FormIntegrationStatusData,
) => {
  return {
    type: ACTION_TYPE.SET_FORM_INTEGRATION_STATE,
    board,
    folder,
    node,
    integrationState,
  }
}

const setProperties = (
  board: string,
  folder?: string,
  node?: string,
  properties?: INodeProps,
) => ({
  type: ACTION_TYPE.SET_PROPERTIES,
  board,
  folder,
  node,
  properties,
})

const actions = {
  copyNodesAction,
  moveNodesAction,
  deleteNodes,
  addTextNode,
  removeNode,
  setProperties,
  setFormIntegrationState,
} as const

export type TNodeActions =
  | ActionCreator<typeof deleteNodes, ACTION_TYPE.DELETE_NODES>
  | ActionCreator<typeof copyNodesAction, ACTION_TYPE.COPY_NODES>
  | ActionCreator<typeof setProperties, ACTION_TYPE.SET_PROPERTIES>
  | ActionCreator<typeof moveNodesAction, ACTION_TYPE.MOVE_NODES>
  | ActionCreator<
      typeof setFormIntegrationState,
      ACTION_TYPE.SET_FORM_INTEGRATION_STATE
    >

export default actions
