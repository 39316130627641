import React, {
  FC,
  ReactComponentElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DraggableList, AdditionalField, FieldTypes } from 'boards-web-ui'

import useCheckoutAnalytics from '@features/analytics/useCheckoutAnalytics'

import AddButton from '../../../components/AddButton'
import { useCheckout } from '../hooks'
import { ADDITIONAL_FIELDS_TYPE } from '../constants'

import AdditionalFieldComponent from './AdditionalField'

import styles from './AdditionalFields.module.css'

type AdditionalFieldsProps = {
  onAfterAddAdditionalField?: (additionalField?: AdditionalField) => void
}
const AdditionalFields: FC<AdditionalFieldsProps> = ({
  onAfterAddAdditionalField,
}) => {
  const { t } = useTranslation()
  const { appCheckoutAdditionalFieldsView, appCheckoutQuestionAddEvent } =
    useCheckoutAnalytics()

  useEffect(() => {
    appCheckoutAdditionalFieldsView()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    additionalFields,
    onAdditionalFieldsReOrder,
    onAdditionalFieldsChange,
    onAdditionalFieldsDelete,
    onAdditionalFieldsAdd,
  } = useCheckout({
    onAfterAddAdditionalField,
  })
  const [autoFocusIndex, setAutoFocusIndex] = useState<number>(0)

  const additionalFieldsReorderHandler = (items?: ReactNode[]) => {
    const additionalFieldsInNewOrder: Array<AdditionalField> | undefined =
      items?.map((additionalFieldComponent) => {
        const AdditionalFieldProps = (
          additionalFieldComponent as ReactComponentElement<
            typeof AdditionalFieldComponent
          >
        ).props
        return {
          ...AdditionalFieldProps.field,
        }
      })

    onAdditionalFieldsReOrder(additionalFieldsInNewOrder || [])
  }

  const onAdditionFieldBlurHandler = useCallback(
    (additionalField: AdditionalField) => {
      if (!additionalField.value?.trim()?.length) {
        onAdditionalFieldsChange({
          ...additionalField,
          value:
            t(ADDITIONAL_FIELDS_TYPE[additionalField.type]?.defaultValue) || '',
        })
      }
    },
    [onAdditionalFieldsChange, t],
  )

  const renderAdditionalFields = useMemo(() => {
    return additionalFields.map((field: AdditionalField, index: number) => (
      <AdditionalFieldComponent
        key={field.id}
        field={field}
        autoFocus={autoFocusIndex === index && !field.value}
        onChange={(updatedField) => {
          onAdditionalFieldsChange({
            ...field,
            ...updatedField,
          })
        }}
        placeholder={
          field.type === FieldTypes.CHOICE
            ? t('label_type_your_question')
            : t('forms_add_field_label')
        }
        onBlur={() => onAdditionFieldBlurHandler(field)}
        onDelete={() => onAdditionalFieldsDelete(field)}
        data-key={field.id}
      />
    ))
  }, [
    t,
    autoFocusIndex,
    additionalFields,
    onAdditionalFieldsChange,
    onAdditionalFieldsDelete,
    onAdditionFieldBlurHandler,
  ])

  return (
    <>
      <span className={styles.RequiredLabel}>
        {(additionalFields || []).length > 0 && (
          <Trans i18nKey={'label_required'} />
        )}
      </span>
      <DraggableList
        onReOrderEnd={additionalFieldsReorderHandler}
        className={styles.AdditionalFields}
      >
        {renderAdditionalFields}
      </DraggableList>
      <AddButton
        onClick={() => {
          appCheckoutQuestionAddEvent()
          onAdditionalFieldsAdd()
          setAutoFocusIndex(additionalFields.length)
        }}
      >
        {t('action_add_field')}
      </AddButton>
    </>
  )
}

export default React.memo(AdditionalFields)
