import { useDispatch } from 'react-redux'
import { userActions } from 'actions'
import { useSelectorFactory } from '@hooks/useSelectorFactory'
import { selectProfileInformation } from '../../../../selectors'

export interface IUserEmailPreferences {
  offers: boolean
  tips: boolean
  product: boolean
}

export const useUserEmailPreferences = () => {
  const dispatch = useDispatch()
  const profile = useSelectorFactory(selectProfileInformation)

  const updateUserEmailPreferences = (preferences: IUserEmailPreferences) =>
    dispatch(userActions.setUserEmailPreferences(preferences))

  return {
    updateUserEmailPreferences,
    emailPreferences: profile?.preferences?.emails,
  } as const
}
