import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useFolderEvents from '@features/analytics/useFolderEvents'
import usePageEvents from '@features/analytics/usePageEvents'
import useBoard from '@hooks/useBoard'
import { useToast } from '@hooks/useToast'

import { nodeActions } from '../../../../actions'
import { Node, NodeType } from '../../../models'

import useFolder from '../../../../hooks/useFolder'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'

const useNodeDeletorAPI = (boardId: string, folderId?: string) => {
  const toast = useToast()
  const { isPageBoard } = useBoard()
  const dispatch = useDispatch()
  const { deleteFolderEvent, folderDeleteContentEvent } = useFolderEvents()
  const { deletePageEvent, pageDeleteContentEvent } = usePageEvents()
  const { folder } = useFolder(boardId, folderId)

  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()

  const deleteNode = useCallback(
    (node: Node, successMessage?: string, parentFolderId?: string) => {
      if (!isApiAvailableOrNotify()) return

      dispatch(
        nodeActions.deleteNodes(boardId, parentFolderId || folderId, [node.id]),
      )

      if (node.type === NodeType.PAGE) {
        deletePageEvent(node, boardId, isPageBoard)
      } else if (node.type === NodeType.FOLDER) {
        deleteFolderEvent(node, boardId)
      } else if (folder?.isPage) {
        pageDeleteContentEvent(node, boardId, parentFolderId || folderId)
      } else {
        folderDeleteContentEvent(boardId, node)
      }

      if (successMessage) {
        toast(successMessage, false, 300, true)
      }
    },
    [
      boardId,
      folderId,
      folder?.isPage,
      dispatch,
      isApiAvailableOrNotify,
      toast,
      deletePageEvent,
      isPageBoard,
      deleteFolderEvent,
      pageDeleteContentEvent,
      folderDeleteContentEvent,
    ],
  )

  return { deleteNode }
}

export default useNodeDeletorAPI
