import * as UndoStack from './UndoStack'
import { initialState } from '../../initialState'
import { AppState } from '../../../app/models/appState'

export function undoRedoReducer(
  state: AppState,
  boardId: string,
  undo: boolean,
): AppState {
  if (boardId === undefined) return state

  const board = state.boards[boardId]
  const folders = state.folders[boardId]
  if (board === undefined) return state

  const result = undo
    ? UndoStack.undo(state.app.undoStack, boardId, { board, folders })
    : UndoStack.redo(state.app.undoStack, boardId, { board, folders })

  if (result.content === undefined) return state

  return {
    ...state,
    boards: {
      ...state.boards,
      [boardId]: {
        ...result.content.board,
        action: { id: result.content.board.id },
      },
    },
    folders: {
      ...state.folders,
      [boardId]: result.content.folders,
    },
    app: {
      ...state.app,
      undoStack: result.undoStack as AppState['app']['undoStack'],
      selectedNodeId: undefined,
    },
  }
}

export function resetUndoStackReducer(state: AppState): AppState {
  const { undo, redo } = state.app.undoStack
  if (undo.length === 0 && redo.length === 0) {
    return state
  }

  return {
    ...state,
    app: {
      ...state.app,
      undoStack: initialState.app.undoStack,
    },
  }
}

export function setUndoActionReducer(
  state: AppState,
  boardId: string,
  content: unknown,
): AppState {
  return {
    ...state,
    undoActions: {
      ...state.undoActions,
      [boardId]: content,
    },
  }
}
