import { AppState } from '../../app/models/appState'
import { PROFILE_INFO_ACTION_TYPE } from '../../actions'
import {
  setPayPalAuthorizationReducer,
  setUserProfileNameReducer,
  syncProfileInformationReducer,
} from './profileInfoReducers'
import { TProfileInfoActions } from '../../actions/profile'

export function profileInfoReducer(
  state: AppState,
  action: TProfileInfoActions,
): AppState | undefined {
  switch (action.type) {
    case PROFILE_INFO_ACTION_TYPE.SET_USER_PROFILE_NAME:
      return setUserProfileNameReducer(state, action.name)

    case PROFILE_INFO_ACTION_TYPE.UPDATE_PROFILE_INFO:
    case PROFILE_INFO_ACTION_TYPE.SYNC_PROFILE_INFO:
      return syncProfileInformationReducer(state, action)

    case PROFILE_INFO_ACTION_TYPE.SET_PAYPAL_AUTHORIZATION:
      return setPayPalAuthorizationReducer(state, action)

    default:
      return undefined
  }
}
