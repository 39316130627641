/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { NodeType } from 'app/models'

export interface IRest {
  [id: string]: string
}

export const AnalyticsNodeType = {
  [NodeType.FOLDER]: NodeType.FOLDER,
  [NodeType.VIDEO]: NodeType.VIDEO,
  [NodeType.VIDEO_STREAMING]: 'video_hosted',
  [NodeType.AUDIO]: 'voice',
  [NodeType.IMAGE]: 'photo',
  [NodeType.TEXT]: NodeType.TEXT,
  [NodeType.LINK]: NodeType.LINK,
  [NodeType.PDF]: NodeType.PDF,
  [NodeType.PAGE]: NodeType.PAGE,
  [NodeType.PARAGRAPH]: NodeType.PARAGRAPH,
  [NodeType.HEADLINE]: NodeType.HEADLINE,
  [NodeType.BUTTON]: NodeType.BUTTON,
  [NodeType.FORM]: 'app_form_delete',
  [NodeType.CHECKOUT]: 'app_checkout_delete',
  [NodeType.PROFILE]: 'profile',
}

export enum EventCategories {
  APP_START_TRIAL_VIEW = 'app_start_trial_view',
  APP_START_TRIAL_CLICK = 'app_start_trial_click',
  APP_TRIAL_END_VIEW = 'app_trial_end_screen_view',
  APP_TRIAL_END_DISMISS_CLICK = 'app_trial_end_screen_dismiss_click',
  APP_TRIAL_END_UPGRADE_CLICK = 'app_trial_end_screen_upgrade_click',
  APP_TRIAL_REMINDER_VIEW = 'app_trial_reminder_view',
  APP_TRIAL_REMINDER_DISMISS = 'app_trial_reminder_dismiss',

  APP_AUDIO_PLAY = 'app_audio_play',
  APP_VIDEO_PLAY = 'app_video_play',
  APP_HOME_SCREEN_VIEW = 'app_home_screen_view',

  APP_BOARD_CREATE = 'app_board_create',
  APP_BOARD_COPIED = 'app_board_copied',
  APP_BOARD_EDIT = 'app_board_edit',
  APP_BOARD_DELETE = 'app_board_delete',
  APP_BOARD_LEAVE = 'app_board_leave',
  APP_BOARD_ACTIVATE_CLICK = 'app_board_activate_click',

  APP_FOLDER_CREATE = 'app_folder_create',
  APP_FOLDER_EDIT = 'app_folder_edit',
  APP_FOLDER_DELETE = 'app_folder_delete',
  APP_FOLDER_VIEW = 'app_folder_view',
  APP_WEB_FOLDER_VIEW = 'app_web_folder_view',
  APP_JOIN_BOARD = 'app_join_board',
  APP_CONTENT_CREATE = 'app_content_create',
  APP_CONTENT_EDIT = 'app_content_edit',
  APP_CONTENT_DELETE = 'app_content_delete',
  APP_CONTENT_VIEW = 'app_content_view',
  APP_CONTENT_COPY = 'app_content_copy',
  APP_CONTENT_MOVE = 'app_content_move',
  APP_CONTENT_SHARE = 'app_content_share',

  APP_ADD_CONTENT_MENU_ITEM_CLICK = 'app_add_content_menu_item_click',
  APP_PAGE_ADD_CONTENT_MENU_ITEM_CLICK = 'app_page_add_content_menu_item_click',

  APP_PAGE_CREATE = 'app_page_create',
  APP_PAGE_EDIT = 'app_page_edit',
  APP_PAGE_DELETE = 'app_page_delete',
  APP_PAGE_VIEW = 'app_page_view',
  APP_FORM_DELETE = 'app_form_delete',
  APP_CHECKOUT_DELETE = 'app_checkout_delete',
  APP_PAGE_CONTENT_DELETE = 'app_page_content_delete',
  APP_PAGE_CONTENT_INSERT_FROM_BOARDS = 'app_page_content_insert_from_boards',
  APP_PAGE_CONTENT_CLICK = 'app_page_content_click',
  APP_PAGE_CONTENT_VIEW = 'app_page_content_view',
  APP_PAGE_EDITOR_VIEW = 'app_page_editor_view',
  APP_FORM_CREATE = 'app_form_create',
  APP_FORM_EDIT = 'app_form_edit',

  APP_PAGE_CONTENT_CREATE = 'app_page_content_create',
  APP_PAGE_CONTENT_EDIT = 'app_page_content_edit',

  APP_TOOLTIP_VIEW = 'app_tooltip_view',
  APP_TOOLTIP_CLOSED = 'app_tooltip_closed',
  APP_BUTTON_CLICK = 'app_button_click',
  APP_BUTTON_MENU_VIEW = 'app_page_buttons_menu_view',
  APP_BUTTON_EDITOR_VIEW = 'app_page_buttons_editor_view',
  APP_BUTTON_MESSENGER_SEARCH_CLICK = 'app_page_buttons_messenger_search_click',
  APP_SCREEN_VIEW = 'app_screen_view',
  APP_BOARD_VIEW = 'app_board_view',
  APP_BOARD_INVITE_LINK_CREATE = 'app_board_invite_link_create',
  APP_BOARD_INVITE_LINK_DEACTIVATE = 'app_board_invite_link_deactivate',
  APP_BOARD_INVITE_LINK_COPIED = 'app_board_invite_link_copied',

  APP_CHANGE_DISCARD = 'app_change_discard',

  APP_MESSAGE_VIEW = 'app_update_screen_message_view',
  APP_MESSAGE_POP_UP_VIEW = 'app_popup_update_confirm_view',
  APP_MESSAGES_TOOLTIP_VIEW = 'app_messages_tooltip_view',
  APP_MESSAGES_FREE_BANNER_VIEW = 'app_messages_free_banner_view',
  APP_MESSAGES_OVER_BANNER_VIEW = 'app_messages_over_banner_view',

  APP_TOAST_UPDATE_SENT_SHOWN = 'app_toast_update_sent_shown',

  APP_EDIT_NAME = 'app_edit_name',

  APP_POPUP_ADD_NAME_SHOWN = 'app_popup_add_name_shown',
  APP_POPUP_ADD_NAME_SAVED = 'app_popup_add_name_saved',
  APP_POPUP_ADD_NAME_DISMISSED = 'app_popup_add_name_dismissed',
  APP_POPUP_DELETE_UPDATE_SHOWN = 'app_popup_delete_update_shown',

  APP_FIRST_CONTENT_EDIT = 'app_first_content_edit',

  UPDATES_PANE_OPEN = 'updates_pane_open',
  UPDATES_PANE_CLOSE = 'updates_pane_close',

  APP_INSIGHTS_FILTER_CHANGED = 'app_insights_filter_changed',
  APP_INSIGHTS_DATE_MESSAGE_DISPLAYED = 'app_insights_date_message_displayed',
  APP_TIMELINE_HOVER_SHOW = 'app_timeline_hover_shown',
  APP_HOVER_TOOLTIP_HOVERED = 'app_hover_tooltip_hovered',

  APP_BOARD_MEMBERS_ADD = 'app_board_members_add',
  APP_BOARD_MEMBERS_REMOVE = 'app_board_members_remove',
  APP_BOARD_MEMBERS_FORWARD = 'app_board_members_forward',
  APP_MEMBER_LIST_EXPORT = 'app_member_list_export',
  APP_BOARD_MEMBER_CHANGE_PERMISSION = 'app_board_member_change_permission',

  APP_BANNER_MONETIZATION_SHOW = 'app_banner_monetization_shown',
  APP_BANNER_MONETIZATION_DISMISS = 'app_banner_monetization_dismiss',
  APP_BANNER_MONETIZATION_CLICK = 'app_banner_monetization_click',

  APP_FORM_QUESTION_ADD_CLICK = 'app_form_add_field_click',
  APP_FORM_QUESTION_ADDED = 'app_form_question_added',
  APP_FORM_QUESTION_DELETED = 'app_form_question_deleted',
  APP_FORM_QUESTION_MULTIPLE_CHOICE_TOGGLE_SWITCH = 'app_form_multiple_choice_toggle_switch',
  APP_FORM_QUESTION_MULTIPLE_CHOICE_OPTION_ADDED = 'app_form_multiple_choice_add_option',
  APP_FORM_QUESTION_MULTIPLE_CHOICE_OPTION_DELETED = 'app_form_multiple_choice_option_deleted',
  APP_FORM_EDIT_SCREEN_VIEW = 'app_form_edit_screen_view',
  APP_INSERT_CONTENT_DIALOG_VIEW = 'app_insert_content_dialog_view',
  APP_FORM_RESPONSES_EMAIL_CHANGED = 'app_form_responses_email_changed',

  APP_DESKTOP_CTAS_MOBILE_CLICK = 'app_desktop_ctas_mobile_click',
  APP_DESKTOP_CTAS_CHROME_CLICK = 'app_desktop_ctas_chrome_click',

  APP_DESKTOP_CTAS_DISMISS = 'app_desktop_ctas_dismiss',

  APP_PHONE_VERIFICATION_SCREEN_VIEW = 'app_phone_verification_screen_view',
  APP_PHONE_VERIFICATION_COUNTRY_CLICK = 'app_phone_verification_country_click',
  APP_PHONE_VERIFICATION_SEND_CLICK = 'app_phone_verification_send_click',
  APP_PHONE_VERIFICATION_ERROR = 'app_phone_verification_error',
  APP_PHONE_VERIFICATION_SKIP_CLICK = 'app_phone_verification_skip_click',

  APP_PHONE_VERIFICATION_CODE_SCREEN_VIEW = 'app_phone_verification_code_screen_view',
  APP_PHONE_VERIFICATION_CODE_BACK_CLICK = 'app_phone_verification_code_back_click',
  APP_PHONE_VERIFICATION_CODE_SUBMITED = 'app_phone_verification_code_submited',
  APP_PHONE_VERIFICATION_CODE_RESEND_CLICK = 'app_phone_verification_code_resend_click',
  APP_PHONE_VERIFICATION_CODE_SKIP_CLICK = 'app_phone_verification_code_skip_click',
  APP_PHONE_VERIFICATION_CODE_RESEND_SKIP_VIEW = 'app_phone_verification_code_resend_skip_view',
  APP_PHONE_VERIFICATION_CODE_RESEND_SKIP_CLICK = 'app_phone_verification_code_resend_skip_click',
  APP_PHONE_VERIFICATION_CODE_ERROR = 'app_phone_verification_code_error',
  APP_PHONE_VERIFICATION_PHONE_VERIFIED_SUCCESSFULLY = 'app_phone_verification_phone_verified_successfully',

  APP_PAGES_BOARD_VIEW = 'app_pages_board_view',

  APP_PAGES_BOARD_SHOW_IN_EXTENSION = 'app_pages_board_show_in_extension',
  APP_PAGES_BOARD_HIDE_FROM_EXTENSION = 'app_pages_board_hide_from_extension',

  APP_FEEDBACK_CLICK = 'app_feedback_click',
  APP_LOGOUT_CLICK = 'app_logout_click',
  APP_INFO_CLICK = 'app_info_click',

  APP_PAYWALL_VIEW = 'app_paywall_view',
  APP_PAYWALL_SOURCE = 'app_paywall_source',
  APP_PAYWALL_TAB_CLICK = 'app_paywall_tab_click',
  APP_PAYWALL_DIFFERENT_PLANT_CLICK = 'app_paywall_different_plan_click',
  APP_PAYWALL_SUBSCRIBE_CLICK = 'app_paywall_subscribe_click',
  APP_PAYWALL_CYCLE_CLICK = 'app_paywall_cycle_click',
  APP_SUBSCRIPTION_START = 'app_subscription_start',

  APP_SUBSCRIPTION_CLICK = 'app_subscription_click',
  APP_EDIT_PLAN_CLICK = 'app_edit_plan_click',
  APP_CHANGE_PLAN_CLICK = 'app_change_plan_click',
  APP_CANCEL_PLAN_CLICK = 'app_cancel_plan_click',
  APP_CONFIRM_CANCEL_CLICK = 'app_confirm_cancel_click',
  APP_DISMISS_CANCEL_CLICK = 'app_dismiss_cancel_click',
  APP_CHANGE_METHOD_CLICK = 'app_change_method_click',
  APP_RENEW_PLAN = 'app_renew_plan',

  APP_EMAIL_PREFERENCES_VIEW = 'app_email_preferences_view',
  APP_EMAIL_PREFERENCES_CLICK = 'app_email_preferences_click',
  APP_EMAIL_OFFERS_CLICK = 'app_email_offers_click',
  APP_EMAIL_RECOMMENDATIONS_CLICK = 'app_email_recommendations_click',
  APP_EMAIL_PRODUCT_CLICK = 'app_email_product_click',

  APP_DESTINATION_PICKER_VIEW = 'app_destination_picker_view',

  APP_PROFILING_DISPLAY = 'app_user_profiling_display',
  APP_PROFILING_COMPLETE = 'app_user_profiling_completed',
  APP_PROFILING_ANSWERED_TEAM_LEAD = 'app_answered_team_lead',
  APP_PROFILING_INDUSTRY = 'app_profiling_q_userIndustryV1',
  APP_PROFILING_BRAND_TEXT = 'app_profiling_q_brandNameV1',
  APP_PROFILING_BRAND_SELECT = 'app_profiling_q_brandNameV1',
  APP_PROFILING_TYPE = 'app_profiling_q_userTypeV1',
  APP_PROFILING_TEAM_SIZE = 'app_profiling_q_teamSizeV1',
  APP_PROFILING_BRAND_SELECT_TEXT = 'app_profiling_q_brandNameV1',
  APP_PROFILING_TIME_IN_BUSINESS = 'app_profiling_q_timeActive',
  APP_PROFILING_PROFILING_DOWNLINE = 'app_profiling_q_userTypeV1',
  APP_PROFILING_PROFILING_DOWNLINE_SIZE = 'app_profiling_q_teamSizeV1',
  APP_PROFILING_PROFILING_DOWNLINE_RANK = 'app_profiling_q_userTypeV1',

  APP_FORM_SETTINGS_SCREEN_VIEW = 'app_form_settings_screen_view',
  APP_GOOGLE_SHEETS_INTEGRATION_CLICK = 'app_google_sheets_integration_click',
  APP_GOOGLE_SHEETS_INTEGRATION_SUCCESS = 'app_google_sheets_integration_success',
  APP_FORM_POST_SUBMISSION_REDIRECT_SELECT = 'app_form_post_submission_redirect_select',
  APP_FORM_POST_SUBMISSION_REDIRECT_EDIT = 'app_form_post_submission_redirect_edit',
  APP_FORM_POST_SUBMISSION_MESSAGE_SELECT = 'app_form_post_submission_message_select',
  APP_FORM_POST_SUBMISSION_MESSAGE_EDIT = 'app_form_post_submission_message_edit',

  APP_PAGE_UNSUBSCRIBED_BANNER_VIEW = 'app_page_unsubscribed_banner_view',
  APP_PAGE_UNSUBSCRIBED_BANNER_UPGRADE_CLICK = 'app_page_unsubscribed_banner_upgrade_click',
  APP_PAGE_CO_EDITORS_NO_ACCESS_POPUP_VIEW = 'app_page_co_editors_no_access_popup_view',

  APP_PAGE_CONTENT_BLOCKED_VIEW = 'app_content_blocked_view',
  APP_PAGE_CONTENT_BLOCKED_CLICKED = 'app_page_content_blocked_clicked',

  APP_CHECKOUT_DISCONNECT = 'app_checkout_disconnect',
  APP_CHECKOUT_ADDITIONAL_FIELDS_VIEW = 'app_checkout_additional_fields_view',
  APP_CHECKOUT_CREATE = 'app_checkout_create',
  APP_CHECKOUT_EDIT = 'app_checkout_edit',
  APP_CHECKOUT_SETTINGS_VIEW = 'app_checkout_settings_view',
  APP_CONNECT_PAYPAL_CLICK = 'app_connect_paypal_click',
  APP_PAYPAL_INTEGRATION_SUCCESS = 'app_paypal_integration_success',

  APP_PAGE_TEMPLATE_CATEGORY_VIEW = 'app_page_template_category_view',
  APP_PAGE_TEMPLATE_CATEGORY_CLICK = 'app_page_template_category_click',
  APP_PAGE_TEMPLATE_PREVIEW_SCREEN_VIEW = 'app_page_template_preview_screen_view',
  APP_PAGE_TEMPLATE_CATEGORY_START_FROM_SCRATCH = 'app_page_template_category_start_from_scratch',
  APP_PAGE_CHOOSE_THIS_TEMPLATE_CLICK = 'app_page_choose_this_template_click',
  APP_PAGE_PERSONALIZE_TEMPLATE_DISMISS = 'app_page_personalize_template_dismiss',

  APP_CONTENT_REPLACE = 'app_content_replace',
  APP_PAGE_CONTENT_REPLACE = 'app_page_content_replace',

  APP_MEMBERS_SCREEN_VIEW = 'app_members_screen_view',
  APP_CO_EDITOR_SCREEN_VIEW = 'app_co_editor_screen_view',

  APP_PAGE_IMAGE_REPOSITION_VIEW = 'app_page_image_reposition_view',
  APP_PAGE_IMAGE_REPOSITION_DONE = 'app_page_image_reposition_done',
}
