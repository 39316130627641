import { PAGES, TEMPLATES } from '../../../constants/routes'

export const getTemplateIdUpdatedUrl = ({
  templateId,
  searchParams,
  isPagesBoardRoute,
  boardId,
  folderId,
}: {
  templateId: string
  searchParams: URLSearchParams
  isPagesBoardRoute: boolean
  boardId: string
  folderId: string
}) => {
  const rootPath = isPagesBoardRoute
    ? `/${PAGES}/${TEMPLATES}`
    : `/${boardId}/${folderId}/${TEMPLATES}`
  return `${rootPath}/${templateId}?${new URLSearchParams(searchParams)}`
}

export const getTemplatePreviewCloseUrl = ({
  searchParams,
  isPagesBoardRoute,
  boardId,
  folderId,
}: {
  searchParams: URLSearchParams
  isPagesBoardRoute: boolean
  boardId: string
  folderId: string
}) => {
  const rootPath = isPagesBoardRoute
    ? `/${PAGES}/${TEMPLATES}`
    : `/${boardId}/${folderId}/${TEMPLATES}`
  return `${rootPath}?${new URLSearchParams(searchParams)}`
}
