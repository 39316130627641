import { SUBSCRIPTIONS_ACTION_TYPE } from '../../actions'
import { AppState } from '../../app/models/appState'
import { initialState } from '../initialState'
import {
  cancelCurrentSubscriptionReducer,
  renewCurrentSubscriptionReducer,
  setAvailableSubscriptionsLoadingReducer,
  setAvailableSubscriptionsReducer,
} from './palansSubscriptionReducers'
import { TSubscriptionsActions } from '../../actions/subscription'

export function plansSubscriptionReducer(
  state: AppState,
  action: TSubscriptionsActions,
): AppState | undefined {
  switch (action.type) {
    case SUBSCRIPTIONS_ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS_LOADING:
      return setAvailableSubscriptionsLoadingReducer(state, action.discountId)

    case SUBSCRIPTIONS_ACTION_TYPE.RESET_AVAILABLE_SUBSCRIPTIONS:
      return {
        ...state,
        plans: initialState.plans,
      }

    case SUBSCRIPTIONS_ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        plans: {
          status: 'failed',
          data: {
            current: null,
            plans: [],
          },
        },
      }

    case SUBSCRIPTIONS_ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS:
      return setAvailableSubscriptionsReducer(state, action)

    case SUBSCRIPTIONS_ACTION_TYPE.RENEW_CURRENT_SUBSCRIPITON:
      return renewCurrentSubscriptionReducer(state)

    case SUBSCRIPTIONS_ACTION_TYPE.CANCEL_CURRENT_SUBSCRIPITON:
      return cancelCurrentSubscriptionReducer(state, action)

    default:
      return undefined
  }
}
