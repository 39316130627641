import { t } from 'i18next'

const useToolbarTranslations = () => {
  const addBoardPlaceholder = (): string => t('hint_add_board_name')
  const addFolderPlaceholder = (): string => t('hint_add_folder_name')
  const addPagePlaceholder = (): string => t('page_web_name_placeholder')
  const defaultBoardTitle = (): string => t('default_title_my_board')
  const defaultFolderTitle = (): string => t('default_title_my_folder')
  const defaultPageTitle = (): string => t('page_name_missing_placeholder')
  const renameBoardTooltip = (): string => t('desktop_hover_rename_board')
  const changeEmojiTooltip = (): string => t('desktop_hover_change_emoji')
  const renameFolderTooltip = (): string => t('desktop_hover_rename_folder')
  const renamePageTooltip = (): string => t('desktop_hover_rename_page')
  const viewMode = (): string => t('view_mode')
  const canEditViewModeTooltip = (): string => t('desktop_hover_view_only')
  const undoAction = (): string => t('action_undo')
  const redoAction = (): string => t('action_redo')
  const shareButton = (): string => t('action_share')
  const membersCount = ({
    digit,
    suffix,
  }: {
    digit: number
    suffix?: string
  }): string => t('share_screen_subtitle', { count: digit, suffix })
  const deletePageAction = (): string => t('action_delete_page')
  const deletePageTitle = (): string => t('dialog_delete_page_title')
  const deletePageConfirmation = (): string => t('dialog_delete_page_body')

  const deleteFolderAction = (): string => t('delete_folder')
  const deleteFolderTitle = (): string =>
    t('confirmation_message_delete_folder')
  const deleteFolderConfirmation = (): string =>
    t('dialog_delete_folder_are_you_sure')

  const deleteBoardAction = (): string => t('delete_board')
  const deleteBoardTitle = (): string => t('dialog_delete_board_title')
  const deleteBoardConfirmation = (): string =>
    t('dialog_delete_board_are_you_sure_shared_board')
  const addMembers = (): string => t('action_add_members')
  const cancelDelete = (): string => t('action_cancel')
  const confirmDelete = (): string => t('action_delete')
  const folderDeleted = (): string => t('desktop_toast_folder_deleted')
  const pageDeleted = (): string => t('toast_page_deleted')
  const boardDeleted = (): string => t('desktop_toast_board_deleted')
  const areYorSure = (): string => t('confirmation_text_are_you_sure')
  const areYorSureBody = (): string => t('confirmation_body_are_you_sure')
  const selectKeysAction = (): string => t('action_select_keys')
  const leaveBoardConfirmation = (): string => t('dialog_leave_board_title')
  const leaveBoardConfirmationBody = (): string =>
    t('dialog_leave_board_are_you_sure')
  const leaveBoardAction = (): string => t('action_leave_board')
  const leaveAction = () => t('action_leave')
  const menuPage = () => t('menu_page')
  const showPagesBoardAction = () => t('pages_board_show_in_chrome')
  const hidePagesBoardAction = () => t('pages_board_hide_from_chrome')
  const pagesBoardTitle = () => t('label_web_pages')

  return {
    addBoardPlaceholder,
    addFolderPlaceholder,
    addPagePlaceholder,
    defaultBoardTitle,
    defaultFolderTitle,
    defaultPageTitle,
    renameBoardTooltip,
    changeEmojiTooltip,
    renameFolderTooltip,
    renamePageTooltip,
    viewMode,
    canEditViewModeTooltip,
    undoAction,
    redoAction,
    shareButton,
    membersCount,
    deleteFolderAction,
    deleteFolderTitle,
    deleteFolderConfirmation,
    deletePageAction,
    deletePageTitle,
    deletePageConfirmation,
    addMembers,
    cancelDelete,
    confirmDelete,
    folderDeleted,
    pageDeleted,
    deleteBoardAction,
    deleteBoardTitle,
    deleteBoardConfirmation,
    areYorSure,
    areYorSureBody,
    selectKeysAction,
    leaveBoardConfirmation,
    leaveBoardConfirmationBody,
    leaveBoardAction,
    leaveAction,
    boardDeleted,
    menuPage,
    showPagesBoardAction,
    hidePagesBoardAction,
    pagesBoardTitle,
  }
}

export default useToolbarTranslations
