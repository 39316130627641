import {
  FeatureBoards,
  FeatureMessages,
  FeatureVideoUpload,
} from '@models/userFeatures'
import {
  UserProfileFeatureLimit,
  UserProfilePlanFeatures,
  UserProfilePlanLimits,
} from '@models/UserProfile'

import { selectProfileInformation } from '../selectors'
import { useSelectorFactory } from './useSelectorFactory'

export const enum Features {
  messages = 'announcements',
  boards = 'boards',
  uploadVideo = 'uploadVideo',
}

export type Feature = {
  [Features.messages]: FeatureMessages
  [Features.boards]: FeatureBoards
  [Features.uploadVideo]: FeatureVideoUpload
}

const FEATURE_USAGE_AND_LIMIT = {
  [Features.messages]: 'messages',
  [Features.boards]: 'boards',
  [Features.uploadVideo]: 'videos',
}

export const FREE_PLAN_LIMITS = {
  messages: 10,
  video: 1,
}

const useUserFeatures = <T extends Features>(featureName: T) => {
  const { plan, usage } = useSelectorFactory(selectProfileInformation)

  const featureKey = FEATURE_USAGE_AND_LIMIT[
    featureName
  ] as keyof UserProfilePlanLimits

  const featureLimit = plan.limits[featureKey]

  const featureUsage = usage[featureKey]

  const isEnabled = () =>
    plan.features[featureName as keyof UserProfilePlanFeatures]

  const isTryFree = (limit: number) => featureLimit === limit

  const isLimitReached = () => {
    if (featureLimit === 'unlimited') {
      return false
    }

    return featureLimit - featureUsage <= 0
  }

  const calculateFreeCountLeft = (): UserProfileFeatureLimit => {
    if (featureLimit === 'unlimited') {
      return 'unlimited'
    }

    return featureLimit - featureUsage
  }

  const limit = (): UserProfileFeatureLimit => featureLimit

  const calculateUsedCount = () => {
    return Number(limit()) - Number(calculateFreeCountLeft())
  }

  const features: Feature = {
    [Features.uploadVideo]: {
      enabled: isEnabled(),
      tryFree: isTryFree(FREE_PLAN_LIMITS.video),
      limitReached: isLimitReached(),
    },
    [Features.messages]: {
      tryFree: isTryFree(FREE_PLAN_LIMITS.messages),
      limitReached: isLimitReached(),
      freeCountLeft: calculateFreeCountLeft(),
    },
    [Features.boards]: {
      limit: limit(),
      limitReached: isLimitReached(),
      freeCountLeft: calculateFreeCountLeft(),
      usedCount: calculateUsedCount(),
    },
  }

  return features[featureName] as Feature[T]
}

export default useUserFeatures
