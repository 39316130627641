import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useLogUsageEvents from '@features/analytics/useLogUsageEvents'
import { useSharedFolder } from '@hooks/useSharedFolder'
import { Folder, NodeType } from '../../../models'
import { folderActions, nodeActions } from '../../../../actions'

import useNodeActions from './useNodeActions'
import useBulkSelection from './useBulkSelection'
import { BoardMode, INode } from '../models/nodes'

const useNode = ({
  boardId,
  folderId,
  mode,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
}: {
  boardId: string
  folderId?: string
  mode: BoardMode
  folder?: Folder
  isSharedFolderMode?: boolean
  canCopyContentOfSharedFolder?: boolean
}): INode => {
  const dispatch = useDispatch()
  const { viewNode } = useLogUsageEvents()
  const { previewNode, openFolder } = useNodeActions(
    folder,
    isSharedFolderMode,
    canCopyContentOfSharedFolder,
    boardId,
    folderId,
  )
  const { startSelection, toggleNodeSelection, isSelected } = useBulkSelection()

  const {
    sharedFolderState: { shortcutId },
  } = useSharedFolder()

  const onNodeClick = (nodeId: string, type: NodeType) => {
    if (type === NodeType.FOLDER) {
      if (isSharedFolderMode && folder && shortcutId) {
        const [user, shortCut] = shortcutId.split('.')
        const selectedFolder = folder.content.find((item) => nodeId === item.id)
        const newShortcutId = `${user}.${selectedFolder?.shortcut}`
        dispatch(folderActions.setFolderShortcut(shortCut))
        dispatch(
          folderActions.updateSharedFolderData({
            shortcutId: newShortcutId,
            boardId,
            folderId,
          }),
        )

        return
      }
      openFolder(boardId, nodeId)
      return
    }

    previewNode(nodeId, type !== NodeType.PAGE)

    viewNode(nodeId)
  }

  const onClick: INode['onClick'] = ({
    nodeId,
    type,
    unSelectable,
    isNotSameFolderIdForBulkAction,
  }) => {
    if (mode === BoardMode.action && ![NodeType.FOLDER].includes(type)) {
      return
    }

    if (mode === BoardMode.select && !isNotSameFolderIdForBulkAction) {
      if (unSelectable) {
        return
      }

      toggleNodeSelection(nodeId, type)
      return
    }

    onNodeClick(nodeId, type)
  }

  const onSelect: INode['onSelect'] = useCallback(
    (nodeId, type) => {
      if ([BoardMode.read, BoardMode.write].includes(mode)) {
        startSelection({ boardId, folderId, shortcutId })
        toggleNodeSelection(nodeId, type)
        return
      }

      if (mode === BoardMode.select) {
        toggleNodeSelection(nodeId, type)
      }
    },
    [boardId, folderId, mode, shortcutId, startSelection, toggleNodeSelection],
  )

  const moveNode = (nodeId: string, beforeNodeId: string | undefined) => {
    dispatch(
      nodeActions.moveNodesAction({
        targetBoardId: boardId,
        targetFolderId: folderId,
        nodes: [nodeId],
        sourceFolderId: folderId,
        beforeNodeId,
      }),
    )
  }

  return {
    isSelected,
    onClick,
    onSelect,
    moveNode,
  }
}

export default useNode
