import { CLIENT_CONFIG_TYPE } from '../../actions'
import { AppState } from '../../app/models/appState'
import { setClientConfigReducer } from './setClientConfigReducer'
import { TClientConfigActions } from '../../actions/clientConfig'

export function clientConfigReducer(
  state: AppState,
  action: TClientConfigActions,
): AppState | undefined {
  switch (action.type) {
    case CLIENT_CONFIG_TYPE.SET_CLIENT_CONFIG:
      return setClientConfigReducer(state, action.data, action.clientFeature)

    default:
      return undefined
  }
}
