import { AppState } from '../../app/models/appState'
import { FOLDER_ACTION_TYPE } from '../../actions'
import {
  addFakeFolderReducer,
  addFolderReducer,
  deleteFolderReducer,
  deleteFolderShortcutReducer,
  setFolderShortcutReducer,
  setFoldersReducer,
  setSharedFolderReducer,
  updateSharedFolderDataReducer,
} from './foldersReducers'
import { setActionInProgressReducer } from '../general/genralRedusers'
import { TFoldersActions } from '../../actions/folder'

export function foldersReducer(
  state: AppState,
  action: TFoldersActions,
): AppState | undefined {
  switch (action.type) {
    case FOLDER_ACTION_TYPE.SET_FOLDER:
      return setFoldersReducer(state, action.boardId, action.folders)

    case FOLDER_ACTION_TYPE.ADD_FOLDER: {
      return setActionInProgressReducer(
        addFolderReducer(
          state,
          action.board,
          action.folder,
          action.key,
          action.properties,
        ),
      )
    }

    case FOLDER_ACTION_TYPE.ADD_FOLDER_FAKE:
      return addFakeFolderReducer(
        state,
        action.board,
        action.folder,
        action.key,
        action.properties,
        !!action?.isPage,
        action.picker,
      )

    case FOLDER_ACTION_TYPE.DELETE_FOLDER:
      return deleteFolderReducer(state, action)

    case FOLDER_ACTION_TYPE.SET_FOLDER_SHORTCUT:
      return setFolderShortcutReducer(state, action.shortcut)

    case FOLDER_ACTION_TYPE.DELETE_FOLDER_SHORTCUT:
      return deleteFolderShortcutReducer(state, action.shortcut)

    case FOLDER_ACTION_TYPE.SET_SHARED_FOLDER:
      return setSharedFolderReducer(state, action.folder)

    case FOLDER_ACTION_TYPE.UPDATE_SHARED_FOLDER_DATA:
      return updateSharedFolderDataReducer(
        state,
        action.shortcutId,
        action.boardId,
        action.folderId,
      )

    default: {
      return undefined
    }
  }
}
