import { saveTermsAndConditionsAcceptance, setUserName } from '../_firebase'
import { asyncActions as firebaseEventsAsyncActions } from './firebaseEvents'
import { AppDispatch } from '../app/store'

export const enum ACTION_TYPE {
  START_FREE_TRIAL = 'START_FREE_TRIAL',
  SET_USER_EMAIL_PREFERENCES = 'SET_USER_EMAIL_PREFERENCES',
}

const startFreeTrial = (userId: string) => ({
  type: ACTION_TYPE.START_FREE_TRIAL,
  userId,
})

const setUserEmailPreferences = (preferences: {
  offers: boolean
  tips: boolean
  product: boolean
}) => ({
  type: ACTION_TYPE.SET_USER_EMAIL_PREFERENCES,
  preferences,
})

const finishUserCreation =
  (
    name: string,
    creationEventParams: Record<string, unknown>,
    { agreeEmailConsent }: { agreeEmailConsent: boolean },
  ) =>
  async (
    dispatch: AppDispatch,
    _: unknown,
    {
      firebaseAPI,
    }: {
      firebaseAPI: { getReceivedBoards: () => { size: number } }
    },
  ) => {
    await setUserName(name)
    await saveTermsAndConditionsAcceptance('1.0')

    const { size } = await firebaseAPI.getReceivedBoards()

    if (size > 0) {
      dispatch(
        firebaseEventsAsyncActions.logEvent(
          'app_new_account_with_received_boards',
        ),
      )
    } else {
      dispatch(
        firebaseEventsAsyncActions.logEvent('app_new_account_without_boards'),
      )
    }

    dispatch(firebaseEventsAsyncActions.logEvent('app_sign_up'))
    dispatch(
      firebaseEventsAsyncActions.logEvent(
        'app_account_creation',
        creationEventParams,
      ),
    )

    dispatch(
      setUserEmailPreferences(
        agreeEmailConsent
          ? { offers: true, tips: true, product: true }
          : { offers: false, tips: false, product: false },
      ),
    )
  }

const actions = {
  startFreeTrial,
  setUserEmailPreferences,
} as const

export const asyncActions = {
  finishUserCreation,
} as const

export default actions
