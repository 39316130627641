import { Board, UiUpdatesMessage, UpdatesMessage } from '../app/models'

export const mergeMessageWithBoard = (
  message: UpdatesMessage,
  { title, icon }: Board,
  unread: boolean,
): UiUpdatesMessage => ({
  title,
  // TODO check and fix this
  // @ts-expect-error TS2783: unread is specified more than once, so this usage will be overwritten.
  unread,
  icon,
  ...message,
})
