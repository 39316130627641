import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useSelectorFactory } from '@hooks/useSelectorFactory'

import { CampaignBanners } from '../../../../_firebase/models/campaigns'
import { campaignAsyncActions } from '../../../../actions'
import { campaignsBanners } from '../../../../selectors'

const useCampaign = () => {
  const dispatch = useDispatch()

  // TODO fix types for camping banners
  const banners: CampaignBanners = useSelectorFactory(
    campaignsBanners,
  ) as unknown as CampaignBanners

  useEffect(() => {
    dispatch(campaignAsyncActions.getCampaignBanners())
  }, [dispatch])

  const onCloseBanner = useCallback(
    (bannerId: string) => {
      dispatch(campaignAsyncActions.markCampaignBannerAsShown([bannerId]))
    },
    [dispatch],
  )

  return {
    banners,
    onCloseBanner,
  } as const
}

export default useCampaign
