import React, { useCallback, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import {
  ButtonNew,
  CardActions,
  CardBody,
  CardBodyContent,
  CardHeader,
  useDialog,
} from 'boards-web-ui'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import useReverseTrialEvents from '@features/reverseTrial/hooks/useReverseTrialEvents'
import { ProfileFeatures } from '@models/UserProfile'
import useProfileFeatureFlag from '@hooks/useProfileFeatureFlag'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './ReverseTrialStartv2.module.css'
import { TEMPLATES } from '../../../../constants/routes'

const ReverseTrialStartV2 = () => {
  const { open } = useDialog()

  useEffect(() => {
    open(<ReverseTrialStartDialogV2 />, {
      fullScreen: true,
      overlayBackgroundColor: 'rgba(0, 0, 0, 0.7)',
      closeOnEscapeButton: false,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return null
}

const ReverseTrialStartDialogV2 = () => {
  const { trialDaysAvailable, handleStartReverseTrial } =
    useReverseTrialContext()
  const { startTrialViewEvent, startTrialClickEvent } = useReverseTrialEvents()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { isFeatureAvailable: isTemplatesFeatureAvailable } =
    useProfileFeatureFlag(ProfileFeatures.TEMPLATES)

  useEffect(() => {
    startTrialViewEvent(trialDaysAvailable || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCallBackClick = useCallback(() => {
    if (!isTemplatesFeatureAvailable) {
      navigate(`${pathname}/${TEMPLATES}`, {
        state: {
          source: 'addPage',
        },
      })
    }
  }, [isTemplatesFeatureAvailable, navigate, pathname])

  const handleUpgradeClick = () => {
    startTrialClickEvent({
      trialDaysAvailable: trialDaysAvailable || 0,
      trialDaysLeft: trialDaysAvailable || 0,
      isReverseTrial: true,
    })

    setIsLoading(true)
    handleStartReverseTrial(handleCallBackClick)
  }

  return (
    <div className={styles.Root}>
      <CardHeader className={styles.IconContainer} icon={<>📣</>} />

      <CardBody className={styles.Container}>
        <CardBodyContent className={styles.Content}>
          <div className={styles.Title}>
            <Trans i18nKey="reverse_start_existing_popup_title_v2" />
          </div>

          <div className={styles.Message}>
            <Trans
              i18nKey="reverse_start_existing_popup_body1_v2"
              values={{ day: trialDaysAvailable }}
              components={{ strong: <span style={{ fontWeight: '500' }} /> }}
            />
          </div>
        </CardBodyContent>

        <CardActions className={styles.Actions}>
          <ButtonNew
            fullWidth
            isLoading={isLoading}
            size={'large'}
            onClick={handleUpgradeClick}
          >
            <Trans i18nKey="reverse_start_existing_popup_button" />
          </ButtonNew>
        </CardActions>
      </CardBody>
    </div>
  )
}

export default ReverseTrialStartV2
