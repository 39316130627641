import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  REVERT_ACTION = 'REVERT_ACTION',
  STOP_ACTION_IN_PROGRESS = 'STOP_ACTION_IN_PROGRESS',
}

const revertAction = (state: unknown) => ({
  type: ACTION_TYPE.REVERT_ACTION,
  state,
})

const stopActionInProgress = () => ({
  type: ACTION_TYPE.STOP_ACTION_IN_PROGRESS,
})

const actions = {
  revertAction,
  stopActionInProgress,
} as const

export type TGeneralActions =
  | ActionCreator<typeof revertAction, ACTION_TYPE.REVERT_ACTION>
  | ActionCreator<
      typeof stopActionInProgress,
      ACTION_TYPE.STOP_ACTION_IN_PROGRESS
    >

export default actions
