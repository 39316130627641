import { CAMPAIGN_ACTION_TYPE } from '../../actions'
import { AppState } from '../../app/models/appState'
import { TCampaignActions } from '../../actions/campaign'
import {
  getCampaignBannersSuccessReducer,
  getCampaignSuccessReducer,
  hideCampaignBannerReducer,
} from './campaignReducers'

export function campaignReducer(
  state: AppState,
  action: TCampaignActions,
): AppState | undefined {
  switch (action.type) {
    case CAMPAIGN_ACTION_TYPE.GET_CAMPAIGN_SUCCESS:
      return getCampaignSuccessReducer(state, action)
    case CAMPAIGN_ACTION_TYPE.GET_CAMPAIGN_BANNERS_SUCCESS:
      return getCampaignBannersSuccessReducer(state, action)
    case CAMPAIGN_ACTION_TYPE.HIDE_CAMPAIGN_BANNER:
      return hideCampaignBannerReducer(state, action)

    default:
      return undefined
  }
}
