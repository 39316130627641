import { EDIT_LINK_ACTION_TYPE } from '../../actions'
import { AppState } from '../../app/models/appState'
import { TEditLinkActions } from '../../actions/editLink'
import {
  editLinkErrorReducer,
  editLinkLoadingReducer,
  editLinkPageStepReducer,
  editLinkTitleReducer,
  resetEditLinkReducer,
} from './editLinkReducers'

export function editLinkReducer(
  state: AppState,
  action: TEditLinkActions,
): AppState | undefined {
  switch (action.type) {
    case EDIT_LINK_ACTION_TYPE.SET_EDIT_LINK_ERROR:
      return editLinkErrorReducer(state, action.errorMessage)

    case EDIT_LINK_ACTION_TYPE.SET_EDIT_LINK_PAGE_STEP:
      return editLinkPageStepReducer(state, action.pageStep)

    case EDIT_LINK_ACTION_TYPE.SET_EDIT_LINK_LOADING:
      return editLinkLoadingReducer(state, action.loading)

    case EDIT_LINK_ACTION_TYPE.SET_EDIT_LINK_TITLE:
      return editLinkTitleReducer(state, action.title)

    case EDIT_LINK_ACTION_TYPE.RESET_EDIT_LINK_STATE:
      return resetEditLinkReducer(state)

    default:
      return undefined
  }
}
