import { AppState } from '../../app/models/appState'

export function editLinkErrorReducer(
  state: AppState,
  errorMessage: string | null,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        errorMessage,
        editLink: {
          errorMessage,
        },
      },
    },
  }
}

export function editLinkPageStepReducer(
  state: AppState,
  pageStep: number,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        pageStep,
      },
    },
  }
}

export function editLinkLoadingReducer(
  state: AppState,
  loading: boolean,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        loading,
      },
    },
  }
}

export function editLinkTitleReducer(state: AppState, title: string): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        nodeTitle: title,
      },
    },
  }
}

export function resetEditLinkReducer(state: AppState): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      nodeEditor: {
        ...state.app.nodeEditor,
        pageStep: 1,
        nodeTitle: '',
        loading: false,
        editLink: {
          ...state.app.nodeEditor.editLink,
          errorMessage: null,
        },
        nodeVideo: {
          ...state.app.nodeEditor.nodeVideo,
          hasError: false,
        },
      },
    },
  }
}
