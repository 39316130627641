import React, { useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import PublicPaywall from '@features/payments/PublicPaywall'

import useOverwriteRouteRedirect from '@hooks/useOverwriteRouteRedirect'
import PageSignIn from './PageSignIn/PageSignIn'
import PageSignUp from './PageSignUp/PageSignUp'
import PageSingOut from './PageSingOut/PageSingOut'
import PageResetPassword from './PageResetPassword/PageResetPassword'
import PhoneVerification from '../../phoneVerification/PhoneVerification'

import * as ROUTES from '../../../constants/routes'
import { EDITOR, PROFILING, SIGN_IN } from '../../../constants/routes'

const ProfilingWithRedirect = ({ searchParams }: { searchParams: string }) => {
  const { overwriteRouteRedirect } = useOverwriteRouteRedirect()
  const navigate = useNavigate()

  useEffect(() => {
    overwriteRouteRedirect([EDITOR], PROFILING)
    navigate(`${SIGN_IN}?${searchParams}`)
  }, [navigate, overwriteRouteRedirect, searchParams])

  return null
}

const AuthRouting: React.FC = () => {
  const [searchParams] = useSearchParams()

  const getAllSearchParamsAsString = () => {
    let result = ''
    searchParams.forEach((value, key) => {
      result += `&${key}=${value}`
    })
    return result.length > 0 ? result.substring(1) : ''
  }

  return (
    <Routes>
      <Route
        path={ROUTES.PROFILING}
        element={
          <ProfilingWithRedirect searchParams={getAllSearchParamsAsString()} />
        }
      />
      <Route path={ROUTES.PLANS} element={<PublicPaywall />} />
      <Route path={ROUTES.SIGN_IN} element={<PageSignIn />} />
      <Route path={ROUTES.PHONE_VERIFICATION} element={<PhoneVerification />} />
      <Route path={ROUTES.SIGN_UP} element={<PageSignUp />} />
      <Route path={ROUTES.SIGN_OUT} element={<PageSingOut />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<PageResetPassword />} />
      <Route
        path={ROUTES.NOT_FOUND}
        element={
          <Navigate to={`${ROUTES.SIGN_IN}?${getAllSearchParamsAsString()}`} />
        }
      />
    </Routes>
  )
}

export default AuthRouting
