import { UserProfile } from '@models/UserProfile'
import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO',
  SET_PAYPAL_AUTHORIZATION = 'SET_PAYPAL_AUTHORIZATION',
  SYNC_PROFILE_INFO = 'SYNC_PROFILE_INFO',
  SET_USER_PROFILE_NAME = 'SET_USER_PROFILE_NAME',
}

const updateProfileInformation = (info: UserProfile) => ({
  type: ACTION_TYPE.UPDATE_PROFILE_INFO,
  info,
})

const syncProfileInformation = (info: UserProfile) => ({
  type: ACTION_TYPE.SYNC_PROFILE_INFO,
  info,
})

const setPayPalAuthorization = (status: string) => ({
  type: ACTION_TYPE.SET_PAYPAL_AUTHORIZATION,
  status,
})

const setUserProfileName = (name?: string) => ({
  type: ACTION_TYPE.SET_USER_PROFILE_NAME,
  name,
})

const actions = {
  updateProfileInformation,
  syncProfileInformation,
  setPayPalAuthorization,
  setUserProfileName,
} as const

export type TProfileInfoActions =
  | ActionCreator<typeof setUserProfileName, ACTION_TYPE.SET_USER_PROFILE_NAME>
  | ActionCreator<
      typeof updateProfileInformation,
      ACTION_TYPE.UPDATE_PROFILE_INFO
    >
  | ActionCreator<typeof syncProfileInformation, ACTION_TYPE.SYNC_PROFILE_INFO>
  | ActionCreator<
      typeof setPayPalAuthorization,
      ACTION_TYPE.SET_PAYPAL_AUTHORIZATION
    >

export default actions
