import { MEMBERS_ACTION_TYPE, PERMISSION_ACTION_TYPE } from '../../actions'
import { AppState } from '../../app/models/appState'
import {
  addMembersReducer,
  removeMembersReducer,
  stateDeletePermissionReducer,
  stateSetPermissionReducer,
  updatePermissionReducer,
} from './permissionsReducers'
import { TMembersActions } from '../../actions/members'
import { TPermissionsActions } from '../../actions/permission'

export function permissionsReducer(
  state: AppState,
  action: TMembersActions | TPermissionsActions,
): AppState | undefined {
  switch (action.type) {
    case MEMBERS_ACTION_TYPE.ADD_MEMBERS:
      return addMembersReducer(
        state,
        action.board,
        action.emails,
        action.permission,
      )
    case MEMBERS_ACTION_TYPE.REMOVE_MEMBERS:
      return removeMembersReducer(state, action.board, action.emails)

    case PERMISSION_ACTION_TYPE.UPDATE_PERMISSION:
      return updatePermissionReducer(
        state,
        action.board,
        action.email,
        action.permission,
      )

    case PERMISSION_ACTION_TYPE.SET_PERMISSION:
      return stateSetPermissionReducer(
        state,
        action.board,
        action.permissions,
        action.final,
      )

    case PERMISSION_ACTION_TYPE.DELETE_PERMISSION:
      return stateDeletePermissionReducer(state, action.board, action.recipient)

    default:
      return undefined
  }
}
