import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  SET_BOARDS_ORDER = 'SET_BOARDS_ORDER',
}

const setBoardsOrder = (order: Array<string>) => ({
  type: ACTION_TYPE.SET_BOARDS_ORDER,
  order,
})

const actions = {
  setBoardsOrder,
} as const

export type TBoardsOrderActions = ActionCreator<
  typeof setBoardsOrder,
  ACTION_TYPE.SET_BOARDS_ORDER
>

export default actions
