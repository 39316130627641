import { AppState } from '../../app/models/appState'
import { TBoardActions } from '../../actions/board'
import * as FolderManagement from '../FolderManagement'
import { IProperties } from '../FolderManagement'
import { undoStackWithContentAdded } from './undoStackWithContentAdded'
import { TFoldersActions } from '../../actions/folder'
import { TClientConfigActions } from '../../actions/clientConfig'
import { AppAction } from '../appReducers'
import { TProfileInfoActions } from '../../actions/profile'
import { CombinedActions } from '../combinedReducers'
import { TEditLinkActions } from '../../actions/editLink'
import { TUndoRedoActions } from '../../actions/undo-redo'
import { TMembersActions } from '../../actions/members'
import { TPermissionsActions } from '../../actions/permission'
import { TTemplatesActions } from '../../actions/templates'
import { TSubscriptionsActions } from '../../actions/subscription'
import { TCampaignActions } from '../../actions/campaign'

// TODO https://heybliss.atlassian.net/browse/BLISS-10770
export function setActionInProgressReducer(
  state: AppState,
  actionInProgress = true,
): AppState {
  return {
    ...state,
    app: {
      ...state.app,
      actionInProgress,
    },
  }
}

export function setPropertiesReducer(
  state: AppState,
  boardId: string,
  folderId?: string,
  nodeId?: string,
  properties?: { [key: string]: unknown },
): AppState {
  return {
    ...state,
    ...FolderManagement.updateFolders(
      state,
      boardId,
      folderId,
      nodeId,
      // TODO fix this type casting
      properties as unknown as IProperties,
    ),
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, boardId),
    },
  }
}

export type RootAction =
  | TBoardActions
  | TFoldersActions
  | TClientConfigActions
  | AppAction
  | TProfileInfoActions
  | CombinedActions
  | TEditLinkActions
  | TUndoRedoActions
  | TMembersActions
  | TPermissionsActions
  | TTemplatesActions
  | TSubscriptionsActions
  | TCampaignActions

export const combineReducers = (
  state: AppState,
  action: RootAction,
  reducers: ((
    state: AppState,
    // eslint-disable-next-line
    action: RootAction | any,
  ) => AppState | undefined)[],
): AppState | undefined => {
  let resultState: AppState | undefined

  reducers.some((reducer) => {
    resultState = reducer(state, action)

    return resultState
  })

  return resultState
}
