import { TEMPLATES_ACTION_TYPE } from '../../actions'
import { AppState } from '../../app/models/appState'
import { TTemplatesActions } from '../../actions/templates'
import { setTemplatesReducer } from './index'

export function templatesReducer(
  state: AppState,
  action: TTemplatesActions,
): AppState | undefined {
  switch (action.type) {
    case TEMPLATES_ACTION_TYPE.SET_TEMPLATES:
      return setTemplatesReducer(state, action.data)

    default:
      return undefined
  }
}
