import { useCallback } from 'react'
import { useLogEvents } from '@features/analytics/useLogEvents'
import { EventCategories } from '@features/analytics/models/logEvents'

const useProfilingEvents = () => {
  const { trigger } = useLogEvents()

  const showProfiling = useCallback(
    () => trigger(EventCategories.APP_PROFILING_DISPLAY),
    [trigger],
  )

  const answeredTeamLead = useCallback(
    () => trigger(EventCategories.APP_PROFILING_ANSWERED_TEAM_LEAD),
    [trigger],
  )

  const answerQuestion = useCallback(
    (eventName: string, eventValue: string) =>
      trigger(`${eventName}`, { answer: eventValue }),
    [trigger],
  )

  const complete = useCallback(
    () => trigger(EventCategories.APP_PROFILING_COMPLETE),
    [trigger],
  )

  return {
    showProfiling,
    answerQuestion,
    complete,
    answeredTeamLead,
  }
}

export default useProfilingEvents
