import React, { useEffect, useState } from 'react'

import { useAuth } from '@hooks/useAuth'
import useBoard from '@hooks/useBoard'
import useBoardApi from '@hooks/useBoardApi'
import useBoardActions from '@hooks/useBoardActions'
import { ViewSource } from '@features/analytics/models/sourceEvents'
import { SharedBoardIcon } from 'boards-web-ui'
import { IconWrapper } from '@ui/components/IconWrapper'
import useToolbarTranslations from '../hooks/useToolbarTranslations'

import ContentIcon from './ContentIcon'
import ContentTitle, { ContentTitleSize } from './ContentTitle'

import { AddNodeData } from '../models'
import BoardActions from './BoardActions'
import ShareButton from '../../share/ShareButton'

import styles from './BoardTitle.module.css'
import { roundDigitWithSuffix } from '../../../../utils/roundDigitWithSuffix'

const BoardTitle = () => {
  /**
   * TODO: This is a ugly hack to prevent duplicated API calls, should be fixed
   * with new route like /new-board
   */
  const [createdBoardKeys, setCreatedBoardKeys] = useState<string[]>([])
  const { canEditBoardTitle } = useBoardActions()

  const { create, update } = useBoardApi()
  const { user } = useAuth()
  const {
    board,
    boardId,
    isReadOnly,
    permissions,
    isPermissionLoaded,
    isPageBoard,
  } = useBoard()

  const { canShare } = useBoardActions()
  const {
    addBoardPlaceholder,
    defaultBoardTitle,
    renameBoardTooltip,
    changeEmojiTooltip,
    membersCount,
  } = useToolbarTranslations()

  const [newTitle, setNewTitle] = useState<string>(board?.title || '')
  const [newIcon, setNewIcon] = useState<string>(board?.icon || '')
  const [newIsPageBoard, setNewIsPageBoard] = useState<boolean>(isPageBoard)
  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (!board) {
      return
    }
    setNewTitle(board.title)
    setNewIcon(board.icon)
    setNewIsPageBoard(board.isPageBoard)
  }, [board, board?.title, board?.icon, board?.isPageBoard])

  if (!board) {
    return null
  }

  const handleTitleChange = (title: string): void => {
    setNewTitle(title)
  }

  const handleTitleSubmit = (title: string): void => {
    const submitTitle = title || defaultBoardTitle()
    setNewTitle(submitTitle)

    if (isReadOnly || board.title === submitTitle) {
      return
    }

    const node: AddNodeData = { title: submitTitle, icon: board.icon }

    if (board?.key && !createdBoardKeys.includes(board.key)) {
      const uid = user?.uid || ''
      create(uid, board.id, board.key, node, ViewSource.home)
      setCreatedBoardKeys((prev) => [...prev, board?.key || ''])

      return
    }

    update(boardId, board.rootFolderId, board.rootFolderId, node)
  }

  const handleIconChange = (icon: string): void => {
    const node = { title: board.title, icon }
    update(boardId, board.rootFolderId, board.rootFolderId, node)
  }

  const handleEditMode = (state: boolean): void => {
    setEditMode(state)
  }

  return (
    <div className={styles.Inline}>
      <ContentIcon
        readOnly={!canEditBoardTitle}
        className={styles.Icon}
        icon={newIcon}
        onChange={handleIconChange}
        tooltip={changeEmojiTooltip()}
        isPageBoard={newIsPageBoard}
      />

      <ContentTitle
        title={newTitle}
        readOnly={!canEditBoardTitle}
        size={ContentTitleSize.Big}
        autofocus={!newTitle}
        onBlur={(title: string) => {
          handleEditMode(false)
          handleTitleSubmit(title)
        }}
        onFocus={() => (canEditBoardTitle ? handleEditMode(true) : null)}
        onEnter={handleTitleSubmit}
        onChange={handleTitleChange}
        tooltip={renameBoardTooltip()}
        placeholder={addBoardPlaceholder()}
        hideTitleTooltip={newIsPageBoard}
        disabled={newIsPageBoard}
      />

      {!editMode && <BoardActions />}

      {canShare && !editMode && !!permissions.length && isPermissionLoaded && (
        <ShareButton
          component={
            <button className={styles.Members}>
              <IconWrapper>
                <SharedBoardIcon />
              </IconWrapper>
              &nbsp;
              {membersCount(roundDigitWithSuffix(permissions.length))}
            </button>
          }
          membersScreen
        />
      )}
    </div>
  )
}

export default BoardTitle
