/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  arrayUnion,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import {
  ref,
  uploadBytes,
  uploadBytesResumable,
  UploadResult,
} from 'firebase/storage'
import base64ToBlob from '@helpers/base64ToBlob'

import { GetTemplatesResult } from '_firebase/models/templates'
import db, { storage, storagePdf, storageVideo } from './storage'
import { functions } from './functions'
import auth from './auth'
import { callFirebaseFunction } from './utils'

import { setBoardsOrder } from './contentAPI'

export { setBoardsOrder } from './contentAPI'

export * from './campaingFunctions'
export * from './generalFunctions'
export * from './models/content'
export * from './models/auth'
export * from './models/generalFunctions'
export * from './models/campaigns'

// TODO SPLIT this file by function files

export const getUser = () => auth.currentUser
export const getUserId = () => auth.currentUser?.uid.toString()
export const getUserEmail = () =>
  auth.currentUser && auth.currentUser?.email?.toString().toLowerCase()

export const setUserName = (name: string) => {
  if (!auth.currentUser) return
  const { uid } = auth.currentUser

  setDoc(doc(db, `users/${uid}`), { name }, { merge: true })
}

export const getReceivedBoards = () => {
  const email = getUserEmail()
  const q = query(
    collectionGroup(db, 'permissions'),
    where('recipient', '==', email),
  )

  return getDocs(q)
}

export const getBoardPublicKey = async (ownerId: string, boardId: string) => {
  const path = `users/${ownerId}/content/${boardId}/api/permissions`
  const docRef = doc(db, path)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  }

  return { enabled: false, key: null }
}

export const addBoardPublicKey = (
  ownerId: string,
  boardId: string,
  newKey: string,
) => {
  const path = `users/${ownerId}/content/${boardId}/api/permissions`
  const data = { enabled: true, key: newKey }
  const docRef = doc(db, path)
  setDoc(docRef, data)
}

export const updateBoardPublicKey = (
  ownerId: string,
  boardId: string,
  enabled: boolean,
) => {
  const path = `users/${ownerId}/content/${boardId}/api/permissions`
  const data = { enabled }
  const docRef = doc(db, path)
  updateDoc(docRef, data)
}

export const saveContent = (contentId: string, content: any) => {
  if (auth.currentUser) {
    const { uid } = auth.currentUser
    const docRef = doc(db, `users/${uid}/content/${contentId}`)
    setDoc(docRef, {
      content: JSON.stringify(content),
      id: contentId,
    })
  }
}

export const deleteContent = (contentId: string) => {
  if (auth.currentUser) {
    const { uid } = auth.currentUser
    deleteDoc(doc(db, `users/${uid}/content`, contentId))
  }
}

export const addBoardsOrder = (contentId: string) => {
  return setBoardsOrder(arrayUnion(contentId))
}

export const putFile = (file: any, name: string) =>
  uploadBytes(ref(storagePdf, name), file)

export const putImage = (file: any, name: string) =>
  uploadBytes(ref(storage, `images/${name}`), file)

export const uploadBase64Image = async (
  base64Image: string,
  fileName: string,
): Promise<UploadResult> => {
  const imageBlob = base64ToBlob(base64Image, 'image/png')

  return uploadBytes(ref(storage, `images/${fileName}`), imageBlob)
}

export const uploadVideo = (file: Blob, name: string, user: string) => {
  return uploadBytesResumable(ref(storageVideo, name), file, {
    customMetadata: { user },
  })
}

export const generateBoardPublicKey = (ownerId: string, contentId: string) => {
  return callFirebaseFunction(functions, 'permissionsApiGetDefaultKey', {
    ownerId,
    contentId,
  })
}

export const saveTermsAndConditionsAcceptance = (version: string) => {
  return callFirebaseFunction(functions, 'users-setTermsAccepted', {
    version,
  })
}

export const getInsights = async (boardId?: string) => {
  return callFirebaseFunction(functions, 'analytics-getAnalytics', {
    board: boardId,
  })
}

export const copyBoardsApi = async (params: {
  boards: string[]
  key: string
}) => callFirebaseFunction(functions, 'contentHeavyAction/copyBoards', params)

export const applySignupReferralCode = async (referralCode: string) => {
  return callFirebaseFunction(functions, 'affiliates-applySignupReferralCode', {
    referralCode,
  })
}

export const getYouTubeVideoInfo = (id: string) => {
  return callFirebaseFunction(functions, 'getYouTubeVideoSnippet', {
    videoId: id,
  })
}

export const getWebPageProperties = (url: string) => {
  return callFirebaseFunction<{ title: string }>(
    functions,
    'getWebPageProperties',
    {
      url,
    },
  )
}

export const getPageTitle = async (url: string): Promise<string> => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    setTimeout(() => resolve(''), 7000)
    try {
      const data = await getWebPageProperties(url)

      if (data) {
        resolve(data.title)
      } else {
        reject()
      }
    } catch (error) {
      reject()
    }
  })
}

export const getAvailableSubscriptions = (campaign?: string) => {
  const params: { store: string; campaign?: string } = {
    store: 'Paddle',
  }

  if (campaign) {
    params.campaign = campaign
  }

  return callFirebaseFunction(
    functions,
    'monetization-getAvailableSubscriptions',
    params,
  )
}

export interface UpdateSubscriptionProps {
  action: string
  productId?: string
}

export interface UpdateSubscriptionResult {
  transactionId?: string
  effectiveAt: Date
}

export const updateSubscription = (data: UpdateSubscriptionProps) => {
  return callFirebaseFunction<UpdateSubscriptionResult>(
    functions,
    'monetization-updateSubscription',
    data,
  )
}

export const getTheme = async (themeId: string) => {
  const path = `themes/${themeId}`
  const docRef = doc(db, path)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  }
  throw new Error(`Theme with id ${themeId} does not exist`)
}

export const getTemplates = () => {
  return callFirebaseFunction<GetTemplatesResult>(
    functions,
    'library-getTemplates',
  )
}
