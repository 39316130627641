import { IGeolocationData } from 'boards-web-ui'

import { Feature } from '@features/profile/hooks/useProfileInformation'

import { BoardInviteLink } from '../app/models/boardInviteLink'
import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  RESET_STATE = 'RESET_STATE',
  SET_LOADING = 'SET_LOADING',

  SET_FORM_ERRORS = 'SET_FORM_ERRORS',
  SET_INVITE_LINK = 'SET_INVITE_LINK',
  SET_UI_STATE = 'SET_UI_STATE',
  SET_GEO_DATA = 'SET_GEO_DATA',
  SEND_MOBILE_APP_INVITATION_EMAIL = 'SEND_MOBILE_APP_INVITATION_EMAIL',
}

const resetState = () => ({
  type: ACTION_TYPE.RESET_STATE,
})

const setLoading = (loading: boolean) => ({
  type: ACTION_TYPE.SET_LOADING,
  loading,
})

const setFormErrors = (formErrors: { [key: string]: string }) => ({
  type: ACTION_TYPE.SET_FORM_ERRORS,
  formErrors,
})

const setInviteLink = (boardId: string, invite: BoardInviteLink | null) => ({
  type: ACTION_TYPE.SET_INVITE_LINK,
  boardId,
  invite,
})

const setUiState = (property: Feature, value: string | boolean | number) => ({
  type: ACTION_TYPE.SET_UI_STATE,
  property,
  value,
})

const setGeoData = (data: Partial<IGeolocationData> | undefined) => ({
  type: ACTION_TYPE.SET_GEO_DATA,
  data,
})

const sendMobileAppInvitationEmail = () => ({
  type: ACTION_TYPE.SEND_MOBILE_APP_INVITATION_EMAIL,
})

const actions = {
  resetState,
  setLoading,
  setFormErrors,
  setInviteLink,
  setUiState,
  setGeoData,
  sendMobileAppInvitationEmail,
} as const

export type TAppCommonActions =
  | ActionCreator<typeof resetState, ACTION_TYPE.RESET_STATE>
  | ActionCreator<typeof setLoading, ACTION_TYPE.SET_LOADING>
  | ActionCreator<typeof setFormErrors, ACTION_TYPE.SET_FORM_ERRORS>
  | ActionCreator<typeof setInviteLink, ACTION_TYPE.SET_INVITE_LINK>
  | ActionCreator<typeof setUiState, ACTION_TYPE.SET_UI_STATE>
  | ActionCreator<typeof setGeoData, ACTION_TYPE.SET_GEO_DATA>
  | ActionCreator<
      typeof sendMobileAppInvitationEmail,
      ACTION_TYPE.SEND_MOBILE_APP_INVITATION_EMAIL
    >

export default actions
