import { BOARD_ACTION_TYPE } from '../../actions'
import { setFolderPathReducer } from '../foldersReducers/foldersReducers'
import {
  createBoardReducer,
  createFakeBoardReducer,
  createPagesBoardReducer,
  deleteBoardReducer,
  removeBoardReducer,
  setBoardReducer,
} from './boardsReducers'
import { AppState } from '../../app/models/appState'
import { setActionInProgressReducer } from '../general/genralRedusers'
import { TBoardActions } from '../../actions/board'

export function boardsReducer(
  state: AppState,
  action: TBoardActions,
): AppState | undefined {
  switch (action.type) {
    case BOARD_ACTION_TYPE.SET_FOLDER_PATH:
      return setFolderPathReducer(state, action)

    case BOARD_ACTION_TYPE.SET_BOARD:
      return setBoardReducer(state, action.boardId, action.board)

    case BOARD_ACTION_TYPE.CREATE_BOARD: {
      return setActionInProgressReducer({
        ...createBoardReducer(
          state,
          action.owner,
          action.board,
          action.properties,
          action.picker,
        ),
        profileInfo: {
          ...state.profileInfo,
          activeBoards: [...state.profileInfo.activeBoards, action.board],
          usage: {
            ...state.profileInfo.usage,
            boards: state.profileInfo.usage.boards + 1,
          },
        },
      })
    }
    case BOARD_ACTION_TYPE.CREATE_BOARD_FAKE: {
      const { board, owner, key } = action
      const node = { ...action.properties, board, owner, key }
      return createFakeBoardReducer(state, action.owner, action.board, node)
    }
    case BOARD_ACTION_TYPE.CREATE_PAGES_BOARD: {
      const { board, owner, key } = action
      const node = { ...action.properties, board, owner, key }
      return createPagesBoardReducer(state, action.owner, action.board, node)
    }

    case BOARD_ACTION_TYPE.DELETE_BOARD:
      return setActionInProgressReducer(deleteBoardReducer(state, action.board))

    case BOARD_ACTION_TYPE.LEAVE_BOARD:
      return deleteBoardReducer(state, action.board)

    case BOARD_ACTION_TYPE.REMOVE_RECEIVED_DOCUMENT:
      return removeBoardReducer(state, action.documentId)

    default:
      return undefined
  }
}
