import validator from 'validator'

const FaviconAPI =
  'https://s2.googleusercontent.com/s2/favicons?sz=25&domain_url='

const withHttp = (url) =>
  url
    .replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) =>
      schemma ? match : `https://${nonSchemmaUrl}`,
    )
    .trim()

const isValidUrl = (str) => {
  try {
    return validator.isURL(str, { require_protocol: false })
  } catch {
    return false
  }
}

const domainIncludes = (str, search) => {
  if (!isValidUrl(str.trim())) {
    return false
  }

  const { host } = parseUrl(str)

  return host.toLowerCase().indexOf(search.toLowerCase()) !== -1
}

const domainStartsWith = (str, search) => {
  if (!isValidUrl(str.trim())) {
    return false
  }

  const { host } = parseUrl(str)

  return host.toLowerCase().indexOf(search.toLowerCase()) === 0
}

const isPdf = (url) => {
  try {
    const basename = url.split(/[\\/]/).pop()
    return basename.substring(basename.lastIndexOf('.') + 1) === 'pdf'
  } catch (error) {
    return false
  }
}

const parseUrl = (text) => {
  try {
    const { hostname, pathname, host } = new URL(withHttp(text))
    const url = pathname !== '/' ? `${hostname}${pathname}` : hostname
    return { hostname, pathname, url, host: host.replace('www.', '') }
  } catch (error) {
    return { hostname: '', pathname: '', url: '', host: '' }
  }
}

const getFaviconUrl = (url) => {
  return `${FaviconAPI}${withHttp(url)}`
}

const getUrlParameter = (url, param) => {
  const regex = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return undefined
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const sanitizeMessengerUrl = (url) => {
  const regex = /https:\/\/m\.me(?:\/https:\/\/m\.me|\/m\.me)+/
  return url.replace(regex, 'https://m.me')
}

export default {
  getFaviconUrl,
  getUrlParameter,
  isValidUrl,
  domainIncludes,
  domainStartsWith,
  withHttp,
  parseUrl,
  isPdf,
  sanitizeMessengerUrl
}
