import {
  AdditionalFieldType,
  EmailEmptyIcon,
  FieldTypes,
  FormField,
  OrderedListIcon,
  PhoneEmptyIcon,
} from 'boards-web-ui'
import { ReactNode } from 'react'

export const ADDITIONAL_FIELDS_TYPE: {
  [key: string]: {
    id: string
    type: string
    title: string
    icon: ReactNode
    defaultValue: string
    placeholder: string | undefined
  }
} = Object.freeze({
  email: {
    id: FieldTypes.EMAIL,
    type: FieldTypes.EMAIL,
    title: 'label_email_address',
    icon: <EmailEmptyIcon />,
    defaultValue: 'label_email_address',
    placeholder: 'forms_placeholder_email',
  },
  phone: {
    id: FieldTypes.PHONE,
    type: FieldTypes.PHONE,
    title: 'label_phone_number',
    icon: <PhoneEmptyIcon />,
    defaultValue: 'label_phone_number',
    placeholder: 'forms_placeholder_phone_number',
  },
  message: {
    id: FieldTypes.MESSAGE,
    type: FieldTypes.MESSAGE,
    title: 'forms_label_message',
    icon: <OrderedListIcon />,
    defaultValue: 'forms_label_message',
    placeholder: undefined,
  },
  custom: {
    id: FieldTypes.TEXT,
    type: 'custom',
    title: 'forms_label_message',
    icon: '',
    defaultValue: 'forms_label_message',
    placeholder: undefined,
  },
  text: {
    id: FieldTypes.TEXT,
    type: 'custom',
    title: 'forms_label_message',
    icon: '',
    defaultValue: 'forms_label_message',
    placeholder: undefined,
  },
  string: {
    id: FieldTypes.STRING,
    type: FieldTypes.STRING,
    title: 'label_name',
    icon: '',
    defaultValue: 'label_name',
    placeholder: 'forms_placeholder_name',
  },
  choice: {
    id: FieldTypes.CHOICE,
    type: FieldTypes.CHOICE,
    title: 'label_multiple_choice',
    icon: '',
    defaultValue: 'label_multiple_choice',
    placeholder: undefined,
  },
})

export const DEFAULT_ADDITIONAL_FIELDS: FormField[] = [
  {
    id: 'name',
    required: true,
    label: 'label_name',
    type: 'string',
  },
  {
    id: 'phone',
    required: true,
    label: 'label_phone_number',
    type: 'phone',
  },
  {
    id: 'email',
    required: true,
    label: 'label_email_address',
    type: 'email',
  },
  {
    id: 'message',
    label: 'forms_label_message',
    required: false,
    type: 'text',
  },
]

export const DEFAULT_NEW_FIELD: FormField = {
  id: '',
  label: '',
  required: true,
  type: FieldTypes.TEXT,
}

export const getDefaultFieldValuesByFieldType = (
  type: AdditionalFieldType,
  defaultValue: string,
) => {
  return {
    required: type !== FieldTypes.TEXT && type !== FieldTypes.CHOICE,
    label:
      type === FieldTypes.TEXT || type === FieldTypes.CHOICE
        ? ''
        : defaultValue,
    multiple: type === FieldTypes.CHOICE && true,
    choices: type === FieldTypes.CHOICE ? ['', ''] : undefined,
  }
}
