import { combineReducers, RootAction } from './general/genralRedusers'
import { boardsReducer } from './boardsReducers'
import { foldersReducer } from './foldersReducers'
import { clientConfigReducer } from './setClientConfigReducer'
import { appReducer } from './appReducers'
import { profileInfoReducer } from './profileInfoReducers'
import { combinedReducers } from './combinedReducers'
import { editLinkReducer } from './editLinkReducers'
import { undoRedoReducer } from './combinedReducers/undoRedoReducers'
import { permissionsReducer } from './permissionsReducers'
import { templatesReducer } from './templatesReducer/templatesReducer'
import { plansSubscriptionReducer } from './palansSubscriptionReducers'
import { campaignReducer } from './campaignReducers'
import { AppState } from '../app/models/appState'

export const globalReducer = (
  prevState: AppState,
  // eslint-disable-next-line
  action: RootAction | any,
) => {
  // TODO use combine reducers from "redux" for next refactoring
  // https://heybliss.atlassian.net/browse/BLISS-10770
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const result = combineReducers(prevState, action, [
    boardsReducer,
    foldersReducer,
    clientConfigReducer,
    appReducer,
    profileInfoReducer,
    combinedReducers,
    editLinkReducer,
    undoRedoReducer,
    permissionsReducer,
    templatesReducer, // TODO looks like dead code @Rui
    plansSubscriptionReducer,
    campaignReducer,
  ])
  // means that is related type action is handled by combineReducers; no need to check smth below
  if (result) {
    return result
  }

  switch (action.type) {
    default:
      return prevState
  }
}
