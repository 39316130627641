import { AppState } from '../../../app/models/appState'
import { UNDO_REDO_ACTION_TYPE } from '../../../actions'
import { setActionInProgressReducer } from '../../general/genralRedusers'
import {
  resetUndoStackReducer,
  setUndoActionReducer,
  undoRedoReducer as undoRedoStateReducer,
} from './undoRedoReducers'
import { TUndoRedoActions } from '../../../actions/undo-redo'

export function undoRedoReducer(
  state: AppState,
  action: TUndoRedoActions,
): AppState | undefined {
  switch (action.type) {
    case UNDO_REDO_ACTION_TYPE.UNDO:
      return setActionInProgressReducer(
        undoRedoStateReducer(state, action.board, true),
      )

    case UNDO_REDO_ACTION_TYPE.REDO:
      return setActionInProgressReducer(
        undoRedoStateReducer(state, action.board, false),
      )

    case UNDO_REDO_ACTION_TYPE.RESET_UNDO_STACK:
      return resetUndoStackReducer(state)

    case UNDO_REDO_ACTION_TYPE.SET_UNDO_ACTION:
      return setUndoActionReducer(state, action.boardId, action.content)

    default:
      return undefined
  }
}
