import { useTranslation } from 'react-i18next'
import { Folder, Node } from '../../../models'
import useNodeClipboard from '../../nodeEditor/hooks/useNodeClipboard'
import PreviewModal from './PreviewModal'

import styles from './PreviewLink.module.css'
import { usePreviewLinkClick } from '../../../hooks/usePreviewLinkClick'

interface Props {
  node: Node
  next?: () => void
  prev?: () => void
  folder?: Folder
  isSharedFolderMode?: boolean
  canCopyContentOfSharedFolder?: boolean
  sharedBoardId?: string
  sharedFolderId?: string
  close: () => void
}

const PreviewLink = ({
  node,
  prev,
  next,
  folder,
  isSharedFolderMode,
  canCopyContentOfSharedFolder,
  sharedBoardId,
  sharedFolderId,
  close,
}: Props) => {
  const { t } = useTranslation()
  const linkIcon = `https://s2.googleusercontent.com/s2/favicons?sz=32&domain_url=${node.text}`
  const { copyToClipboard } = useNodeClipboard()

  const { handleLinkClick } = usePreviewLinkClick({
    linkUrl: node.text,
    callback: close,
  })

  return (
    <PreviewModal
      nid={node.id}
      copyText={t('action_share')}
      onCopyClick={() => copyToClipboard(node)}
      prev={prev}
      next={next}
      folder={folder}
      isSharedFolderMode={isSharedFolderMode}
      canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
      sharedBoardId={sharedBoardId}
      sharedFolderId={sharedFolderId}
    >
      <div className={styles.Root} onClick={(e) => e.stopPropagation()}>
        <img
          className={styles.Favicon}
          src={linkIcon}
          alt="Favicon"
          loading="lazy"
        />
        <div className={styles.Url}>
          <a
            href={node.text}
            target="_blank"
            rel="noreferrer"
            onClick={handleLinkClick}
          >
            {node.text}
          </a>
        </div>
      </div>
    </PreviewModal>
  )
}

export default PreviewLink
