import { applyMiddleware, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import { globalReducer } from '../reducers'
import createFirebaseMiddleware from '../_firebase/middleware/createFirebaseMiddleware'
import * as firebaseAPI from '../_firebase'
import { logEvent } from '../api/AnalyticsAPI'
import { logUsage } from '../_firebase/contentAPI'
import { initialState } from '../reducers/initialState'

export const history = createBrowserHistory()

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const store = createStore(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalReducer,
  initialState,
  compose(
    applyMiddleware(
      thunk.withExtraArgument({ firebaseAPI, logEvent, logUsage }),
      createFirebaseMiddleware(history),
    ),
    // eslint-disable-next-line
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
      compose,
  ),
)

export default store

export type AppDispatch = typeof store.dispatch
