import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import { MembersPremiumBanner } from '@features/share/components/MembersPremiumBanner'
import { useMembersEvents } from '@features/analytics/useMembersEvents'
import { ReactComponent as MagnifyingGlassIcon } from '../icons/MagnifyingGlass.svg'
import Box from '../../../../elements/Box'
import Select from '../../../../elements/Select'
import Grid from '../../../../elements/Grid'
import useShareOptions from '../hooks/useShareOptions'
import MembersEmptyState from './MembersEmptyState'
import { Permission, Role } from '../../../models'
import MembersRecycledList from './MembersRecycledList'

import styles from './Members.module.css'
import { roundDigitWithSuffix } from '../../../../utils/roundDigitWithSuffix'

interface Props {
  permissions: Permission[]
  selectedEmails: string[]
  role: Role
  toggleUser: (email: string) => void
  goBack: () => void
  onChangePermission: (email: string, permission: string) => void
}
const Members = ({
  permissions,
  selectedEmails,
  role,
  toggleUser,
  goBack,
  onChangePermission,
}: Props) => {
  const { t } = useTranslation()
  const [type, setType] = useState('all')
  const [query, setQuery] = useState('')
  const { shareOptionsWithAll } = useShareOptions()
  const { isFreePlanUser } = useReverseTrialContext()
  const { appCoEditorsScreenView } = useMembersEvents()

  useEffect(() => {
    appCoEditorsScreenView(
      isFreePlanUser ? { banner: 'co_editor_upgrade' } : undefined,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFreePlanUser])

  const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(e.target.value)

  const byQuery = ({ recipient }: Permission): boolean => {
    if (query === '') return true
    return recipient.toLowerCase().search(query.toLowerCase()) !== -1
  }
  const byType = ({ permission }: Permission): boolean => {
    if (type === 'all') return true
    return permission === type
  }

  const handleOnPermissionChange = (email: string, permission: string) => {
    onChangePermission(email, permission)
  }

  if (!permissions.length) {
    return <MembersEmptyState onClickInvite={goBack} />
  }

  const data = permissions
    .filter(byQuery)
    .filter(byType)
    .map(({ recipient, permission }: Permission) => ({
      email: recipient,
      permission,
      role,
      selected: selectedEmails.includes(recipient),
      onClick: () => toggleUser(recipient),
      onChangePermission: handleOnPermissionChange,
      disabledMemberItem: isFreePlanUser && permission === Role.WRITE,
    }))

  const { digit, suffix } = roundDigitWithSuffix(permissions.length)

  return (
    <div className={styles.Members}>
      <h2 className={styles.Title}>
        <Trans i18nKey="share_screen_member_list" />
      </h2>

      <div className={styles.SubTitle}>
        <Trans
          i18nKey="share_screen_subtitle"
          values={{ count: digit, suffix }}
        />
      </div>

      <Box mt={30}>
        {isFreePlanUser ? <MembersPremiumBanner /> : null}

        <Grid rowGap={10} justifyContent={'flex-start'}>
          <Select
            value={type}
            options={shareOptionsWithAll}
            onSelect={setType}
            sx={{ minWidth: '110px' }}
          />

          <MagnifyingGlassIcon className={styles.MagnifyingGlassIcon} />

          <input
            className={styles.SearchInput}
            onChange={handleOnSearchChange}
            type="text"
            placeholder={t('share_screen_search_member')}
          />
        </Grid>
      </Box>

      <Box pt={10} ml={-10} mr={-10}>
        <MembersRecycledList data={data} />
      </Box>
    </div>
  )
}

export default Members
