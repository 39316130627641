import { EventCategories } from './models/logEvents'
import { ViewSource } from './models/sourceEvents'
import { useLogEvents } from './useLogEvents'

export enum AppBoardEditAction {
  redo = 'redo',
  undo = 'undo',
}

const useBoardEvents = () => {
  const { trigger } = useLogEvents()

  const createBoardEvent = ({
    boardId,
    title,
    source,
  }: {
    boardId: string
    title?: string
    source: ViewSource
  }) =>
    trigger(EventCategories.APP_BOARD_CREATE, {
      label: title,
      board_id: boardId,
      source,
    })

  const deleteBoardEvent = (boardId: string, isActive: boolean) =>
    trigger(EventCategories.APP_BOARD_DELETE, {
      board_id: boardId,
      active_board: isActive,
    })
  const leaveBoardEvent = (
    boardId: string,
    boardName: string,
    isActive: boolean,
  ) =>
    trigger(EventCategories.APP_BOARD_LEAVE, {
      board_id: boardId,
      board_name: boardName,
      active_board: isActive,
    })

  const appBoardActivateClick = (
    mode: 'activate' | 'upgrade' = 'activate',
    boardRole: string,
  ) => {
    trigger(EventCategories.APP_BOARD_ACTIVATE_CLICK, {
      mode,
      board_role: boardRole,
    })
  }

  const appHomeScreenView = (
    count: number,
    activeCount: number,
    inactiveCount: number,
  ) =>
    trigger(EventCategories.APP_HOME_SCREEN_VIEW, {
      total_boards: count,
      active_boards: activeCount,
      inactive_boards: inactiveCount,
    })

  const appBoardCopiedEvent = () => {
    trigger(EventCategories.APP_BOARD_COPIED)
  }

  return {
    createBoardEvent,
    deleteBoardEvent,
    leaveBoardEvent,
    appBoardActivateClick,
    appHomeScreenView,
    appBoardCopiedEvent,
  } as const
}

export default useBoardEvents
