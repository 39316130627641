import { Campaign, CampaignBanners } from '../../_firebase/models/campaigns'
import { AppState } from '../../app/models/appState'

export const getCampaignSuccessReducer = (
  state: AppState,
  {
    campaign,
  }: {
    campaign: Campaign
  },
): AppState => {
  return {
    ...state,
    campaigns: {
      [campaign.id]: campaign,
    },
  }
}

export const getCampaignBannersSuccessReducer = (
  state: AppState,
  {
    banners,
  }: {
    banners: CampaignBanners
  },
): AppState => {
  const campaignsBanners = banners
    .filter((banner) => banner.type === 'homescreenBanner')
    .reduce((nextBanners, banner) => {
      return {
        ...nextBanners,
        [banner.id]: banner,
      }
    }, state?.campaignsBanners || {})

  return {
    ...state,
    campaignsBanners,
  }
}

export const hideCampaignBannerReducer = (
  state: AppState,
  { bannerIds }: { bannerIds: Array<string> },
): AppState => {
  const nextCampaignsBanners = {
    ...state.campaignsBanners,
  }
  bannerIds.forEach((id) => delete nextCampaignsBanners[id])

  return {
    ...state,
    campaignsBanners: {
      ...nextCampaignsBanners,
    },
  }
}
