import { NodeType } from '../app/models'
import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  SET_BULK_ACTION = 'SET_BULK_ACTION',
  SET_BULK_ACTION_TYPE = 'SET_BULK_ACTION_TYPE',
  RESET_BULK_ACTION = 'RESET_BULK_ACTION',
  TOGGLE_NODE_BULK_ACTION = 'TOGGLE_NODE_BULK_ACTION',
}

const setBulkAction = (params: {
  boardId: string
  folderId?: string
  shortcutId?: string
}) => ({
  type: ACTION_TYPE.SET_BULK_ACTION,
  ...params,
})

const setBulkActionType = (
  action: 'delete' /* Only "delete" action is remain for the type. Can be refactored later on */,
) => ({
  type: ACTION_TYPE.SET_BULK_ACTION_TYPE,
  action,
})

const toggleNodeBulkAction = (nodeId: string, nodeType: NodeType) => ({
  type: ACTION_TYPE.TOGGLE_NODE_BULK_ACTION,
  nodeId,
  nodeType,
})

const resetBulkAction = () => ({
  type: ACTION_TYPE.RESET_BULK_ACTION,
})

const actions = {
  setBulkAction,
  setBulkActionType,
  resetBulkAction,
  toggleNodeBulkAction,
} as const

export type TBulkActions =
  | ActionCreator<typeof setBulkAction, ACTION_TYPE.SET_BULK_ACTION>
  | ActionCreator<typeof setBulkActionType, ACTION_TYPE.SET_BULK_ACTION_TYPE>
  | ActionCreator<typeof resetBulkAction, ACTION_TYPE.RESET_BULK_ACTION>
  | ActionCreator<
      typeof toggleNodeBulkAction,
      ACTION_TYPE.TOGGLE_NODE_BULK_ACTION
    >

export default actions
