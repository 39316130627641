import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { AnimatedDialog, VideoType, urlHelper, useDialog } from 'boards-web-ui'

import { IVideo, IYoutubeVideo } from '@hooks/useVideo'

import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import DoneButton from 'ui/components/DoneButton'

import FormAddVideo from './FormAddVideo'
import FormEditVideo from './FormEditVideo'
import useAPI, { FUNCTIONS } from '../../../../hooks/useAPI'
import { NodeType } from '../../../models'
import { AppState } from '../../../models/appState'
import ModalEditWithConfirmation from '../../../../components/ModalEditWithConfirmation'
import { editLinkActions, videoActions } from '../../../../actions'
import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'
import useNodeAPI from '../hooks/useNodeAPI'
import useNodeDiscardChanges from '../hooks/useNodeDiscardChanges'

interface Props {
  initialTitle: string
  initialText: string
  onSave: ICreateOrUpdateNode
  isPageEditor?: boolean
}
const AddVideo = ({
  initialTitle,
  initialText,
  onSave,
  isPageEditor,
}: Props) => {
  const { close } = useDialog()
  const discardChanges = useNodeDiscardChanges()
  const [hasError, setHasError] = useState(false)
  const { title, text, hasChanges, setTitle, setText } = useNodeAPI(
    initialTitle,
    initialText,
  )

  const ref = useRef() as MutableRefObject<HTMLInputElement>
  const videoDetails = urlHelper.getVideoDetails(text)
  const dispatch = useDispatch()
  const { pageStep, nodeTitle, loading, nodeVideo } = useSelector(
    (state: AppState) => state.app.nodeEditor,
  )

  const {
    data,
    hasError: hasErrorAPI,
    fetchData,
    isLoading,
  } = useAPI<IYoutubeVideo | IVideo | boolean>(
    videoDetails?.type === VideoType.VIMEO
      ? FUNCTIONS.GET_VIDEO_VIMEO
      : FUNCTIONS.GET_VIDEO_YOUTUBE,
    videoDetails?.id || '',
    false,
  )

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [])

  useEffect(() => {
    setTitle(nodeTitle)
  }, [nodeTitle, setTitle])

  useEffect(() => {
    if (typeof data !== 'boolean' && data?.title) {
      setTitle(data?.title)
      dispatch(editLinkActions.editLinkPageStep(2))
      dispatch(videoActions.videoError(false))
    }

    if (
      !isLoading &&
      data !== undefined &&
      typeof data !== 'boolean' &&
      !data?.title
    ) {
      dispatch(videoActions.videoError(true))
    } else {
      dispatch(videoActions.videoError(false))
    }
  }, [dispatch, data, isLoading, setTitle])

  useEffect(() => {
    dispatch(editLinkActions.editLinkLoading(isLoading))
  }, [dispatch, isLoading])

  useEffect(() => {
    if (pageStep === 2) {
      dispatch(videoActions.videoError(false))
    }
  }, [dispatch, pageStep])

  const handleNext = () => {
    if (text === initialText && title === initialTitle) {
      setHasError(true)
      return
    }

    dispatch(videoActions.videoError(false))
    fetchData()
  }

  const handleSave = () => {
    const video = urlHelper.getVideoDetails(text)
    if (video && !nodeVideo.hasError) {
      onSave({
        title,
        text: video.text,
        type: NodeType.VIDEO,
      })
    }
    // TODO reset state action
    dispatch(editLinkActions.editLinkPageStep(1))
    dispatch(editLinkActions.editLinkTitle(''))
    dispatch(videoActions.videoError(false))
    close()
  }

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: close,
        onOverlayClick: pageStep === 1 ? handleNext : handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            pageStep === 1 ? (
              <DoneButton
                onClick={handleNext}
                disabled={loading}
                loading={loading}
              >
                <Trans i18nKey="action_next" />
              </DoneButton>
            ) : (
              <DoneButton onClick={handleSave}>
                <Trans i18nKey="action_done" />
              </DoneButton>
            )
          }
        >
          <>
            {pageStep === 1 && (
              <FormAddVideo
                ref={ref}
                value={text}
                onInputChange={setText}
                hasError={hasError || hasErrorAPI || nodeVideo.hasError}
              />
            )}
            {pageStep === 2 && (
              <FormEditVideo
                text={text}
                title={title}
                hasError={nodeVideo.hasError}
                onTextChange={setText}
                onTitleChange={!isPageEditor ? setTitle : undefined}
              />
            )}
          </>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default AddVideo
