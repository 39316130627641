import { UserContentSettings } from '@models/UserContentSettings'
import { MessageProperties, UpdatesMessage } from '../app/models'
import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  RECEIVED_UPDATES_MESSAGES = 'RECEIVED_UPDATES_MESSAGES',
  RECEIVED_READ_UNREAD_MESSAGES = 'RECEIVED_READ_UNREAD_MESSAGES',
  MARK_UPDATES_MESSAGES_AS_READ = 'MARK_UPDATES_MESSAGES_AS_READ',
  POST_UPDATES_MESSAGE = 'ADD_UPDATES_MESSAGE',
  DELETE_UPDATES_MESSAGE = 'DELETE_UPDATES_MESSAGE',
  SAVE_UN_POST_UPDATES_MESSAGE = 'SAVE_UN_POST_UPDATES_MESSAGE',
  REMOVE_UN_POST_UPDATES_MESSAGE = 'REMOVE_UN_POST_UPDATES_MESSAGE',
}

const receivedUpdatesMessages = (
  boardId: string,
  messages: UpdatesMessage[],
) => ({
  type: ACTION_TYPE.RECEIVED_UPDATES_MESSAGES,
  boardId,
  messages,
})

const receivedReadUnreadMessages = (
  boardId: string,
  data: UserContentSettings,
) => ({
  type: ACTION_TYPE.RECEIVED_READ_UNREAD_MESSAGES,
  boardId,
  data,
})

const markUpdatesMessagesAsRead = ({
  board,
  messages,
}: {
  board: string
  messages: string[]
}) => ({
  type: ACTION_TYPE.MARK_UPDATES_MESSAGES_AS_READ,
  board,
  messages,
})

const postUpdatesMessage = (message: {
  board: string
  key: string
  properties: MessageProperties
  senderName?: string
}) => ({
  type: ACTION_TYPE.POST_UPDATES_MESSAGE,
  ...message,
})

const deleteUpdatesMessage = (boardId: string, mid: string) => ({
  type: ACTION_TYPE.DELETE_UPDATES_MESSAGE,
  boardId,
  mid,
})

const saveUnPostUpdatesMessage = (postMessageData: {
  board: string
  properties: MessageProperties
}) => ({
  type: ACTION_TYPE.SAVE_UN_POST_UPDATES_MESSAGE,
  postMessageData,
})

const removeUnPostUpdatesMessage = (board: string) => ({
  type: ACTION_TYPE.REMOVE_UN_POST_UPDATES_MESSAGE,
  board,
})

const actions = {
  receivedUpdatesMessages,
  receivedReadUnreadMessages,
  markUpdatesMessagesAsRead,
  postUpdatesMessage,
  deleteUpdatesMessage,
  saveUnPostUpdatesMessage,
  removeUnPostUpdatesMessage,
} as const

export type TUpdatesActions =
  | ActionCreator<
      typeof receivedUpdatesMessages,
      ACTION_TYPE.RECEIVED_UPDATES_MESSAGES
    >
  | ActionCreator<
      typeof receivedReadUnreadMessages,
      ACTION_TYPE.RECEIVED_READ_UNREAD_MESSAGES
    >
  | ActionCreator<typeof postUpdatesMessage, ACTION_TYPE.POST_UPDATES_MESSAGE>
  | ActionCreator<
      typeof deleteUpdatesMessage,
      ACTION_TYPE.DELETE_UPDATES_MESSAGE
    >
  | ActionCreator<
      typeof saveUnPostUpdatesMessage,
      ACTION_TYPE.SAVE_UN_POST_UPDATES_MESSAGE
    >
  | ActionCreator<
      typeof removeUnPostUpdatesMessage,
      ACTION_TYPE.REMOVE_UN_POST_UPDATES_MESSAGE
    >

export default actions
