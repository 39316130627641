import { EventCategories } from '@features/analytics/models/logEvents'
import { useLogEvents } from '@features/analytics/useLogEvents'

export enum SubscriptionSourceEnum {
  Menu = 'menu',
  Account = 'account',
}

export enum PaywallSourceEnum {
  ACTIVATE_BOARD_LIST = 'activate_board_list',
  ACTIVATE_MY_BOARD_LIST = 'activate_my_board_list',
  ACTIVATE_SHARED_BOARD_LIST = 'activate_shared_board_list',
  ACTIVATE_BOARD_SCREEN = 'activate_board_screen',
  ANALYTICS = 'analytics',
  BOARD_HEADER = 'board_header',
  BOARD_LIMIT_REACHED = 'boards_limit_reached',
  CAMPAIGN_BANNERS = 'campaign_banners',
  CHROME_EXTENSION_UPGRADE = 'chrome_extension',
  CREATE_BOARD_HOMESCREEN = 'create_board_homescreen',
  FORM_POST_SUBMISSION = 'form_post_submission',
  FREE_PLAN_UPDATE = 'free_plan_update',
  MESSAGES = 'messages',
  REMOVE_PAGE_CAMPAIGN = 'remove_page_campaign',
  VIDEO_LIMIT_REACHED = 'video_limit_reached',
  SUBSCRIPTIONS_CHANGE_PLAN = 'subscriptions_change_plan',
  COPY_SELECTION = 'copy_selection',
  COPY_CONTENT = 'copy_content',
  MOVE_CONTENT = 'move_content',
  COPY_SHARED_FOLDER = 'copy_shared_folder',
  COPY_CONTENT_SHARED_FOLDER = 'copy_content_shared_folder',

  FORM_CREATE = 'form_create',
  CHECKOUT_CREATE = 'checkout_create',
  FORM_EDIT = 'form_edit',
  CHECKOUT_EDIT = 'checkout_edit',
  PAGE_EDITOR = 'page_editor',
  PAGE_PREVIEW = 'page_preview',

  ACTIVATE_PAGE = 'activate_page',
  EDIT_PAGE = 'edit_page',
  EDIT_CONTENT = 'edit_content',
  SAVE_BOARD = 'save_board',
  ACTIVATE_PAGE_BOARDS = 'activate_pages_board',
  CONTENT_BLOCKED_POPUP = 'content_blocked_popup',

  FREE_PLAN_INDICATION = 'free_plan_indication',
  TRIAL_REMINDER_BANNER = 'trial_reminder',
  TRIAL_ENDED_SCREEN = 'trial_ended',
  START_FROM_SCRATCH = 'page_start_from_scratch',
  DEEPLINK_OPEN = 'deeplink_open_paywall',

  ADD_CO_EDITORS = 'add_co_editors',
  UPGRADE_CO_EDITORS = 'upgrade_co_editors',

  PAGE_ADD_TEMPLATE = 'page_add_template',
}

export enum PaywallSourceTypeEnum {
  DEFAULT = 'default',
  CAMPAIGN = 'campaign',
}

const useAccountSubscriptionEvents = () => {
  const { trigger } = useLogEvents()

  const subscriptionClickEvent = (source: SubscriptionSourceEnum) =>
    trigger(EventCategories.APP_SUBSCRIPTION_CLICK, {
      source,
    })
  const editPlanClickEvent = () => trigger(EventCategories.APP_EDIT_PLAN_CLICK)
  const changePlanClickEvent = () =>
    trigger(EventCategories.APP_CHANGE_PLAN_CLICK)
  const cancelPlanClickEvent = () =>
    trigger(EventCategories.APP_CANCEL_PLAN_CLICK)
  const confirmCancelClickEvent = () =>
    trigger(EventCategories.APP_CONFIRM_CANCEL_CLICK)
  const dismissCancelClickEvent = () =>
    trigger(EventCategories.APP_DISMISS_CANCEL_CLICK)
  const changeMethodClickEvent = () =>
    trigger(EventCategories.APP_CHANGE_METHOD_CLICK)
  const renewPlanEvent = () => trigger(EventCategories.APP_RENEW_PLAN)

  return {
    subscriptionClickEvent,
    editPlanClickEvent,
    changePlanClickEvent,
    cancelPlanClickEvent,
    confirmCancelClickEvent,
    dismissCancelClickEvent,
    changeMethodClickEvent,
    renewPlanEvent,
  }
}

export default useAccountSubscriptionEvents
