import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  OPEN_TOAST_MESSAGE = 'OPEN_TOAST_MESSAGE',
  CLOSE_TOAST_MESSAGE = 'CLOSE_TOAST_MESSAGE',
}

const openToastMessage = (
  message: string,
  global?: boolean,
  showIcon?: boolean,
  customIcon?: string | boolean,
  onClose?: () => void,
) => ({
  type: ACTION_TYPE.OPEN_TOAST_MESSAGE,
  message,
  global,
  showIcon,
  customIcon,
  onClose,
})

const closeToastMessage = () => ({
  type: ACTION_TYPE.CLOSE_TOAST_MESSAGE,
})

const actions = {
  openToastMessage,
  closeToastMessage,
} as const

export type TToastActions =
  | ActionCreator<typeof openToastMessage, ACTION_TYPE.OPEN_TOAST_MESSAGE>
  | ActionCreator<typeof closeToastMessage, ACTION_TYPE.CLOSE_TOAST_MESSAGE>

export default actions
