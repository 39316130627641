import { TemplateCategory } from '_firebase/models/templates'
import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  SET_TEMPLATES = 'SET_TEMPLATES',
}

const setTemplates = (data: TemplateCategory[]) => ({
  type: ACTION_TYPE.SET_TEMPLATES,
  data,
})

const actions = {
  setTemplates,
} as const

export type TTemplatesActions = ActionCreator<
  typeof setTemplates,
  ACTION_TYPE.SET_TEMPLATES
>

export default actions
