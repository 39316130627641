import { useEffect, useMemo } from 'react'
import {
  ImageZooming,
  ImageZoomingMaskVariations,
  PageNodeProfileVariants,
  Spinner
} from 'boards-web-ui'

import { useProfileImageEvents } from '@features/analytics/useProfileImageEvents'

import useRouterParams from '@hooks/useRouterParams'
import useBoard from '@hooks/useBoard'
import useShortcut from '@hooks/useShortcut'
import { Size } from 'app/components/Spinner'
import { CropSettingsProps } from '@features/PageProfile/components/CropImageDialog'

export interface CropImageToolProps {
  variation: PageNodeProfileVariants
  imageSrc: string
  imageWidth: number
  setCropSettings: (cropSettings: CropSettingsProps) => void
}
const CropImageTool = ({
  variation,
  imageSrc,
  imageWidth,
  setCropSettings,
}: CropImageToolProps) => {
  const { boardId } = useRouterParams()
  const { folder } = useBoard()
  const { getShortcut } = useShortcut()

  const { profileImageRepositionView } = useProfileImageEvents()

  const maxZoom = useMemo(() => {
    const zoom = imageWidth ? imageWidth / 450 : 1
    return Math.max(Math.round(zoom * 10) / 10, 1)
  }, [imageWidth])

  useEffect(() => {
    const shortcut = getShortcut(folder)
    profileImageRepositionView(boardId, folder?.id, shortcut)
  }, [boardId, folder, getShortcut, profileImageRepositionView])

  const getVarition = () => {
    switch (variation) {
      case PageNodeProfileVariants.v0:
        return ImageZoomingMaskVariations.v0
      case PageNodeProfileVariants.v1:
        return ImageZoomingMaskVariations.v0
      case PageNodeProfileVariants.v2:
        return ImageZoomingMaskVariations.v2
      case PageNodeProfileVariants.v3:
        return ImageZoomingMaskVariations.v0
      case PageNodeProfileVariants.v4:
        return ImageZoomingMaskVariations.v1
      case PageNodeProfileVariants.v5:
        return ImageZoomingMaskVariations.v3
      case PageNodeProfileVariants.v6:
        return ImageZoomingMaskVariations.v1
      default:
        return ImageZoomingMaskVariations.v0
    }
  }

  if (!imageSrc) {
    return (
      <Spinner size={Size.LARGE} spinning>
        <br />
      </Spinner>
    )
  }

  return (
    <ImageZooming
      maskVariation={getVarition()}
      imgSrc={imageSrc}
      maximalZoomValue={maxZoom}
      onChanged={setCropSettings}
    />
  )
}

export default CropImageTool
