import React, {
  DragEvent,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import { isFirefox } from '@helpers/browser'
import ScrollAreasContext from '@ui/components/Scroll/ScrollAreasContext'
import useDebounceCallback from '@hooks/useDebounceCallback'
import useScrollArea from '@hooks/useScrollArea'

import { ScrollAreaContainer } from '../../../../ui/models/ScrollArea'
import { IDeltaLocation, ILocation } from '../models/nodes'

import styles from './FolderListItem.module.css'

let PageX = 0
let PageY = 0

if (isFirefox()) {
  // Fix missing clientX and clientY on Firefox
  document.addEventListener('dragover', (e) => {
    PageX = e.clientX || e.pageX
    PageY = e.clientY || e.pageY
  })
}

interface ReactProps {
  x: number
  y: number
  width: number
  height: number
}
type Props = PropsWithChildren<{
  index: number
  rect: ReactProps
  onDrag: ((startIndex: number, dropLocation: IDeltaLocation) => void) | null
  onDragEnd: ((startIndex: number) => void) | null
}>
const FolderListItem: FC<Props> = ({
  index,
  rect,
  onDrag,
  onDragEnd,
  children,
}) => {
  const [startLocation, setStartLocation] = useState<ILocation>(rect)
  const [startIndex, setStartIndex] = useState<number>(index)
  const { getArea } = useContext(ScrollAreasContext)
  const {
    scrollToTop,
    scrollToBottom,
    getBoundingClientRectArea,
    getScrollAtBoundary,
  } = useScrollArea()

  const nodeStyle = {
    left: `${rect.x}px`,
    top: `${rect.y}px`,
    width: `${rect.width}px`,
    height: `${rect.height}px`,
  }

  const getEventLocation = (e: DragEvent<HTMLDivElement>) => {
    const areaRef = getArea(ScrollAreaContainer.FOLDER)

    return {
      x: e.pageX || PageX,
      y: (e.pageY || PageY) + (areaRef?.ref?.current?.scrollTop || 0),
    }
  }

  const handleOnDragStart = (e: DragEvent<HTMLDivElement>) => {
    setStartIndex(index)
    setStartLocation(getEventLocation(e))
  }

  const handleScroll = (
    area: ScrollAreaContainer,
    areaRect: DOMRect,
    locationY: number,
    boundaryThreshould: number = 50,
  ) => {
    const { isTop, isBottom } = getScrollAtBoundary(area)

    if (!isTop && locationY < areaRect.top + boundaryThreshould) {
      scrollToTop(area)
    }

    if (!isBottom && locationY > areaRect.bottom - boundaryThreshould) {
      scrollToBottom(area)
    }
  }

  const handleOnDrag = useDebounceCallback((e: DragEvent<HTMLDivElement>) => {
    const location = getEventLocation(e)
    const areaRect = getBoundingClientRectArea(ScrollAreaContainer.FOLDER)

    if (areaRect) {
      handleScroll(ScrollAreaContainer.FOLDER, areaRect, e.clientY)
    }

    const dropLocation = {
      dragDeltaX: location.x - (startLocation?.x || 0),
      dragDeltaY: location.y - (startLocation?.y || 0),
      locationX: location.x,
      locationY: location.y,
    }

    if (onDrag) onDrag(startIndex, dropLocation)
  }, 5)

  const handleOnDragEnd = () => {
    if (onDragEnd) {
      onDragEnd(startIndex)
    }
  }

  if (!onDrag) {
    return (
      <div className={styles.Root} style={nodeStyle}>
        {children}
      </div>
    )
  }

  return (
    <div
      style={nodeStyle}
      className={styles.Root}
      onDrag={handleOnDrag}
      onDragEnd={handleOnDragEnd}
      onDragStart={handleOnDragStart}
      onDragOver={(e) => e.preventDefault()}
      draggable
    >
      {children}
    </div>
  )
}

export default React.memo(FolderListItem)
