import { NODE_LINK_TYPES } from 'boards-web-ui'
import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  SET_BUTTON_ERROR = 'SET_BUTTON_ERROR',
  SET_BUTTON_TYPE = 'SET_BUTTON_TYPE',
  SET_BUTTON_GEO_DATA = 'SET_BUTTON_GEO_DATA',
}

const setButtonError = (buttonError?: string) => ({
  type: ACTION_TYPE.SET_BUTTON_ERROR,
  buttonError,
})

const setButtonType = (buttonType?: NODE_LINK_TYPES) => ({
  type: ACTION_TYPE.SET_BUTTON_TYPE,
  buttonType,
})

const setButtonGeoData = (geoData?: {
  countryCode?: string
  dialCode?: string
}) => ({
  type: ACTION_TYPE.SET_BUTTON_GEO_DATA,
  geoData,
})

const actions = {
  setButtonError,
  setButtonType,
  setButtonGeoData,
} as const

export type TButtonActions =
  | ActionCreator<typeof setButtonError, ACTION_TYPE.SET_BUTTON_ERROR>
  | ActionCreator<typeof setButtonType, ACTION_TYPE.SET_BUTTON_TYPE>
  | ActionCreator<typeof setButtonGeoData, ACTION_TYPE.SET_BUTTON_GEO_DATA>

export default actions
