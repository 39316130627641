import { AppDispatch } from '../app/store'
import {
  AvailableSubscriptionsResult,
  CurrentPlan,
  SubscriptionPlan,
} from '../app/models'
import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  SET_AVAILABLE_SUBSCRIPTIONS = 'SET_AVAILABLE_SUBSCRIPTIONS',
  RESET_AVAILABLE_SUBSCRIPTIONS = 'RESET_AVAILABLE_SUBSCRIPTIONS',
  SET_AVAILABLE_SUBSCRIPTIONS_LOADING = 'SET_AVAILABLE_SUBSCRIPTIONS_LOADING',
  SET_AVAILABLE_SUBSCRIPTIONS_FAILED = 'SET_AVAILABLE_SUBSCRIPTIONS_LOADING_FAILED',
  RENEW_CURRENT_SUBSCRIPITON = 'RENEW_CURRENT_SUBSCRIPITON',
  CANCEL_CURRENT_SUBSCRIPITON = 'CANCEL_CURRENT_SUBSCRIPITON',
}

const availableSubscriptionsLoading = (discountId?: string) => ({
  type: ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS_LOADING,
  discountId,
})

const setAvailableSubscriptions = (
  plans: SubscriptionPlan[],
  current: CurrentPlan,
) => ({
  type: ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS,
  plans,
  current,
})

const resetAvailableSubscriptions = () => ({
  type: ACTION_TYPE.RESET_AVAILABLE_SUBSCRIPTIONS,
})

const setAvailableSubscriptionsFailed = () => ({
  type: ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS_FAILED,
})

const renewCurrentSubscription = () => ({
  type: ACTION_TYPE.RENEW_CURRENT_SUBSCRIPITON,
})

const cancelCurrentSubscription = (scheduledCancel: Date) => ({
  type: ACTION_TYPE.CANCEL_CURRENT_SUBSCRIPITON,
  scheduledCancel,
})

const fetchAvailableSubscriptions =
  (discountId?: string) =>
  async (
    dispatch: AppDispatch,
    getState: () => { plans: { status: string } },
    {
      firebaseAPI,
    }: {
      firebaseAPI: {
        getAvailableSubscriptions: (
          campaignId?: string,
        ) => Promise<AvailableSubscriptionsResult>
      }
    },
  ) => {
    const {
      plans: { status },
    } = getState()

    if (status !== 'loading') {
      try {
        dispatch(availableSubscriptionsLoading(discountId))
        const data = await firebaseAPI.getAvailableSubscriptions(discountId)

        dispatch(setAvailableSubscriptions(data.plans, data.current))
      } catch (err) {
        dispatch(setAvailableSubscriptionsFailed())
      }
    }
  }

const actions = {
  availableSubscriptionsLoading,
  setAvailableSubscriptions,
  setAvailableSubscriptionsFailed,
  fetchAvailableSubscriptions,
  renewCurrentSubscription,
  cancelCurrentSubscription,
  resetAvailableSubscriptions,
} as const

export type TSubscriptionsActions =
  | ActionCreator<
      typeof setAvailableSubscriptions,
      ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS
    >
  | ActionCreator<
      typeof cancelCurrentSubscription,
      ACTION_TYPE.CANCEL_CURRENT_SUBSCRIPITON
    >
  | ActionCreator<
      typeof renewCurrentSubscription,
      ACTION_TYPE.RENEW_CURRENT_SUBSCRIPITON
    >
  | ActionCreator<
      typeof availableSubscriptionsLoading,
      ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS_LOADING
    >
  | ActionCreator<
      typeof resetAvailableSubscriptions,
      ACTION_TYPE.RESET_AVAILABLE_SUBSCRIPTIONS
    >
  | ActionCreator<
      typeof resetAvailableSubscriptions,
      ACTION_TYPE.RESET_AVAILABLE_SUBSCRIPTIONS
    >
  | ActionCreator<
      typeof setAvailableSubscriptionsFailed,
      ACTION_TYPE.SET_AVAILABLE_SUBSCRIPTIONS_FAILED
    >

export const asyncActions = {
  fetchAvailableSubscriptions,
} as const

export default actions
