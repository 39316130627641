import { useSelector } from 'react-redux'
import useFolders from '@hooks/useFolders'
import { useAuth } from '@hooks/useAuth'
import { AppState } from '../app/models/appState'

export const useSharedFolder = () => {
  const sharedFolderState = useSelector(
    (state: AppState) => state.app.sharedFolder,
  )

  const { folders } = useFolders(sharedFolderState.boardId)
  const { user } = useAuth()

  const isSharedFolderExistsInFolders = Boolean(
    folders && sharedFolderState.boardId,
  )

  const showSharedFolder = Boolean(
    sharedFolderState.shortcutId?.length > 0 &&
      sharedFolderState.boardId?.length > 0 &&
      (sharedFolderState?.folderId?.length ?? 0) > 0 &&
      user &&
      !isSharedFolderExistsInFolders,
  )

  return {
    sharedFolderState,
    isSharedFolderExistsInFolders,
    showSharedFolder,
  } as const
}
