import { UserProfile } from '@models/UserProfile'
import { AppState } from '../../app/models/appState'

export function setUserProfileNameReducer(
  state: AppState,
  name?: string,
): AppState {
  return {
    ...state,
    profileInfo: {
      ...state.profileInfo,
      name,
    },
  }
}

export function syncProfileInformationReducer(
  state: AppState,
  { info }: { info: UserProfile },
): AppState {
  return {
    ...state,
    profileInfo: {
      ...info,
      profileIsLoaded: true,
    },
  }
}

export function setPayPalAuthorizationReducer(
  state: AppState,
  { status }: { status: string },
): AppState {
  return {
    ...state,
    profileInfo: {
      ...state.profileInfo,
      payPalAuthorization: {
        ...state.profileInfo.payPalAuthorization,
        status,
      } as unknown as UserProfile['payPalAuthorization'],
    },
  }
}
