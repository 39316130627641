import { useNavigate } from 'react-router-dom'
import React from 'react'

export const usePreviewLinkClick = ({
  linkUrl,
  callback,
}: {
  linkUrl: string
  callback?: () => void
}): {
  handleLinkClick: (event: React.MouseEvent<HTMLAnchorElement>) => boolean
} => {
  const navigate = useNavigate()

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const url = new URL(linkUrl)
    const isDeepLink = window.location.origin === url.origin

    if (!isDeepLink) {
      return false
    }

    event.preventDefault()
    navigate(`${url.pathname}${url.search}`)

    if (callback) {
      callback()
    }

    return true
  }

  return { handleLinkClick }
}
