import {
  ACTION_ACTION_TYPE,
  BOARDS_ORDER_ACTION_TYPE,
  BULK_ACTION_TYPE,
  BUTTON_ACTION_TYPE,
  COMMON_ACTION_TYPE,
  GOOGLE_SHEETS_ACTION_TYPE,
  TOAST_ACTION_TYPE,
  UPLOAD_ACTION_TYPE,
  VIDEO_ACTION_TYPE,
} from '../../actions'
import { AppState } from '../../app/models/appState'
import { initialState } from '../initialState'
import {
  addUploadReducer,
  closeToastMessageReducer,
  openToastMessageReducer,
  removeUploadReducer,
  resetBulkActionReducer,
  setBoardsOrderReducer,
  setBulkActionReducer,
  setBulkActionTypeReducer,
  setButtonErrorReducer,
  setButtonGeoDataReducer,
  setButtonTypeReducer,
  setFormErrorsReducer,
  setGeoDataReducer,
  setInviteReducer,
  setIsGoogleSheetsUrlValidReducer,
  setUiStateReducer,
  stateSetLoadingReducer,
  toggleNodeBulkActionReducer,
  videoErrorReducer,
  videoStreamingIdReducer,
  videoStreamingUrlReducer,
} from './appReducers'
import { TAppCommonActions } from '../../actions/common'
import { TButtonActions } from '../../actions/button'
import { TGoogleSheetsActions } from '../../actions/googleSheets'
import { TVideoActions } from '../../actions/video'
import { TUploadActions } from '../../actions/upload'
import { TBoardsOrderActions } from '../../actions/boardsOrder'
import { TBulkActions } from '../../actions/bulk'
import { TToastActions } from '../../actions/toast'
import { setActionInProgressReducer } from '../general/genralRedusers'
import { TGeneralActions } from '../../actions/action'

export type AppAction =
  | TAppCommonActions
  | TButtonActions
  | TGoogleSheetsActions
  | TVideoActions
  | TUploadActions
  | TBoardsOrderActions
  | TBulkActions
  | TToastActions
  | TGeneralActions

export function appReducer(
  state: AppState,
  action: AppAction,
): AppState | undefined {
  switch (action.type) {
    case COMMON_ACTION_TYPE.RESET_STATE:
      return initialState

    case COMMON_ACTION_TYPE.SET_GEO_DATA:
      return setGeoDataReducer(state, action.data)

    case COMMON_ACTION_TYPE.SET_FORM_ERRORS:
      return setFormErrorsReducer(state, action.formErrors)

    case COMMON_ACTION_TYPE.SET_LOADING:
      return stateSetLoadingReducer(state, action.loading)

    case COMMON_ACTION_TYPE.SET_INVITE_LINK:
      return setInviteReducer(state, action)

    case COMMON_ACTION_TYPE.SET_UI_STATE:
      return setUiStateReducer(state, action)

    case BUTTON_ACTION_TYPE.SET_BUTTON_ERROR:
      return setButtonErrorReducer(state, action.buttonError)
    case BUTTON_ACTION_TYPE.SET_BUTTON_TYPE:
      return setButtonTypeReducer(state, action.buttonType)
    case BUTTON_ACTION_TYPE.SET_BUTTON_GEO_DATA:
      return setButtonGeoDataReducer(state, action.geoData)

    case GOOGLE_SHEETS_ACTION_TYPE.SET_IS_GOOGLE_SHEETS_URL_VALID:
      return setIsGoogleSheetsUrlValidReducer(
        state,
        action.isGoogleSheetsUrlValid,
      )

    case VIDEO_ACTION_TYPE.SET_VIDEO_ERROR:
      return videoErrorReducer(state, action.error)
    case VIDEO_ACTION_TYPE.SET_VIDEO_URL:
      return videoStreamingUrlReducer(state, action.url)
    case VIDEO_ACTION_TYPE.SET_VIDEO_ID:
      return videoStreamingIdReducer(state, action.id)

    case UPLOAD_ACTION_TYPE.ADD_UPLOAD:
      return addUploadReducer(state, action.id)
    case UPLOAD_ACTION_TYPE.REMOVE_UPLOAD:
      return removeUploadReducer(state, action.id)

    case BOARDS_ORDER_ACTION_TYPE.SET_BOARDS_ORDER:
      return setBoardsOrderReducer(state, action.order)

    case BULK_ACTION_TYPE.SET_BULK_ACTION:
      return setBulkActionReducer(
        state,
        action.boardId,
        action.folderId,
        action.shortcutId,
      )
    case BULK_ACTION_TYPE.SET_BULK_ACTION_TYPE:
      return setBulkActionTypeReducer(state, action.action)

    case BULK_ACTION_TYPE.TOGGLE_NODE_BULK_ACTION:
      return toggleNodeBulkActionReducer(state, action.nodeId, action.nodeType)

    case BULK_ACTION_TYPE.RESET_BULK_ACTION:
      return resetBulkActionReducer(state)

    case TOAST_ACTION_TYPE.OPEN_TOAST_MESSAGE:
      return openToastMessageReducer(state, action)

    case TOAST_ACTION_TYPE.CLOSE_TOAST_MESSAGE:
      return closeToastMessageReducer(state)

    case ACTION_ACTION_TYPE.STOP_ACTION_IN_PROGRESS:
      return setActionInProgressReducer(state, false)

    case ACTION_ACTION_TYPE.REVERT_ACTION:
      // TODO https://heybliss.atlassian.net/browse/BLISS-10770
      return {
        // eslint-disable-next-line
        // @ts-ignore
        ...action.state,
        // eslint-disable-next-line
        // @ts-ignore
        app: action.state.app,
      }

    default:
      return undefined
  }
}
