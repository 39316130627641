import { omit } from 'lodash-es'
import { AppState } from '../../app/models/appState'
import { Permission } from '../../app/models'

export function addMembersReducer(
  state: AppState,
  boardId: string,
  emails: string[],
  permission: string,
): AppState {
  const permissions = emails.reduce((acc, email) => {
    return {
      ...acc,
      [email]: {
        recipient: email,
        permission,
      },
    }
  }, {})

  return {
    ...state,
    permissions: {
      ...state.permissions,
      [boardId]: {
        ...state.permissions[boardId],
        ...permissions,
      },
    },
  }
}

export function removeMembersReducer(
  state: AppState,
  boardId: string,
  emails: string[],
): AppState {
  return {
    ...state,
    permissions: {
      ...state.permissions,
      [boardId]: omit(state.permissions[boardId], emails),
    },
  }
}

export function updatePermissionReducer(
  state: AppState,
  boardId: string,
  email: string,
  permission: string,
): AppState {
  const boardIdPermissions = state.permissions[boardId]
  const emailPermissions = boardIdPermissions[email as keyof Permission] ?? {}

  return {
    ...state,
    permissions: {
      ...state.permissions,
      [boardId]: {
        ...boardIdPermissions,
        [email]: {
          ...emailPermissions,
          permission,
        },
      },
    },
  }
}

export function stateSetPermissionReducer(
  state: AppState,
  boardId: string,
  permissions: { [key: string]: Permission },
  final?: boolean,
): AppState {
  const { permissionsLoaded } = state
  if (final) {
    permissionsLoaded[boardId] = true
  }

  return {
    ...state,
    permissions: {
      ...state.permissions,
      [boardId]: {
        ...state.permissions[boardId],
        ...permissions,
      },
    },
    permissionsLoaded,
  }
}

export function stateDeletePermissionReducer(
  state: AppState,
  board: string,
  recipient: string,
): AppState {
  const permission = state.permissions[board]

  if (permission[recipient as keyof Permission]) {
    delete permission[recipient as keyof Permission]
  }

  return {
    ...state,
    permissions: {
      ...state.permissions,
      [board]: permission,
    },
  }
}
