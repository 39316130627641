import { Trans } from 'react-i18next'
import { ButtonIcon, CloseIcon, TrashIcon } from 'boards-web-ui'
import { ModalHeader, ModalHeaderLeft } from '../../../../components/Modal'

import styles from './MembersHeader.module.css'
import { roundDigitWithSuffix } from '../../../../utils/roundDigitWithSuffix'

interface Props {
  members: number
  onClose: () => void
  onDelete: () => void
}
const MembersHeader = ({ members, onClose, onDelete }: Props) => {
  const { digit, suffix } = roundDigitWithSuffix(members)

  return (
    <ModalHeader bgColor="#E5F2FF">
      <ModalHeaderLeft>
        <ButtonIcon onClick={onClose}>
          <CloseIcon className={styles.MembersCloseIcon} />
        </ButtonIcon>

        <span className={styles.MembersCount}>
          <Trans
            i18nKey="share_screen_subtitle"
            values={{ count: digit, suffix }}
          />
        </span>
      </ModalHeaderLeft>

      <ButtonIcon
        onClick={onDelete}
        variant={'blue-transparent'}
        className={styles.MembersDeleteIcon}
      >
        <TrashIcon />
      </ButtonIcon>
    </ModalHeader>
  )
}

export default MembersHeader
