import { TemplateCategory } from '../../_firebase/models/templates'
import { AppState } from '../../app/models/appState'

export function setTemplatesReducer(
  state: AppState,
  data: TemplateCategory[],
): AppState {
  return {
    ...state,
    templates: {
      ...state.templates,
      data,
    },
  }
}
