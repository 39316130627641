import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  FC,
  ReactNode,
} from 'react'
import clsx from 'clsx'
import { useResizeObserver } from 'usehooks-ts'
import { ButtonIcon, Grid } from 'boards-web-ui'

import { ReactComponent as NextIcon } from '@features/nodePreview/icons/Next.svg'
import { ReactComponent as PrevIcon } from '@features/nodePreview/icons/Prev.svg'

import styles from './Carousel.module.css'

const CONTAINER_PADDING = 60

interface CarouselProps {
  itemWidth: number
  columnGap?: number
  children: ReactNode
}

const Carousel: FC<CarouselProps> = ({
  itemWidth,
  columnGap = 20,
  children,
}) => {
  const itemsLength = React.Children.count(children)

  const trackRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { width = 0 } = useResizeObserver({
    ref: containerRef,
  })
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [showPrevArrow, setShowPrevArrow] = useState<boolean>(false)
  const [showNextArrow, setShowNextArrow] = useState<boolean>(true)
  const [currentSideIsLeft, setCurrentSideIsLeft] = useState<boolean>(true)

  const containerWidth = width - columnGap * 2
  const innerContainerWidth = containerWidth - CONTAINER_PADDING
  const totalWidth = itemWidth * itemsLength
  const showArrows = totalWidth > innerContainerWidth
  const itemWidthWithGap = itemWidth + columnGap
  const visibleItems = Math.floor(containerWidth / itemWidthWithGap)

  useEffect(() => {
    setCurrentIndex(0)
    setCurrentSideIsLeft(true)
  }, [children])

  const moveToSlide = useCallback(
    (index: number) => {
      if (!trackRef.current) return

      const translateX = currentSideIsLeft
        ? `translateX(-${index * itemWidthWithGap}px)`
        : `translateX(-${(visibleItems + currentIndex) * itemWidthWithGap - containerWidth + columnGap}px)`

      trackRef.current.style.transform = showArrows ? translateX : ''
      trackRef.current.style.justifyContent = showArrows ? '' : 'center'
    },
    [
      containerWidth,
      currentSideIsLeft,
      currentIndex,
      columnGap,
      showArrows,
      itemWidthWithGap,
      visibleItems,
    ],
  )

  const updateArrowsVisibility = useCallback(() => {
    if (!trackRef.current || !trackRef.current.children.length) {
      return
    }

    if (itemsLength === 0) {
      setShowPrevArrow(false)
      setShowNextArrow(false)
      return
    }

    const currentOffset = currentIndex * itemWidth

    setShowPrevArrow(currentIndex > 0)
    setShowNextArrow(totalWidth - currentOffset > innerContainerWidth)
  }, [
    itemWidth,
    totalWidth,
    currentIndex,
    itemsLength,
    innerContainerWidth,
  ])

  const handleNext = () => {
    if (trackRef.current && containerRef.current) {
      if (currentIndex + visibleItems < itemsLength) {
        setCurrentIndex((prevIndex) => prevIndex + 1)
        setCurrentSideIsLeft(false)
      }
    }
  }

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1)
      setCurrentSideIsLeft(true)
    }
  }

  useEffect(() => {
    moveToSlide(currentIndex)
    updateArrowsVisibility()
  }, [currentIndex, moveToSlide, updateArrowsVisibility])

  return (
    <div className={styles.CarouselContainer} ref={containerRef}>
      {showArrows && (
        <ButtonIcon
          className={clsx(styles.Arrow, styles.PrevIcon)}
          size={46}
          round
          onClick={handlePrev}
          disabled={!showPrevArrow}
        >
          <PrevIcon />
        </ButtonIcon>
      )}
      <div className={styles.Carousel}>
        <Grid
          className={styles.CarouselTrack}
          ref={trackRef}
          columnGap={columnGap}
        >
          {children}
        </Grid>
      </div>
      {showArrows && (
        <ButtonIcon
          className={clsx(styles.Arrow, styles.NextIcon)}
          size={46}
          round
          onClick={handleNext}
          disabled={!showNextArrow}
        >
          <NextIcon />
        </ButtonIcon>
      )}
    </div>
  )
}

export default Carousel
