import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  ADD_UPLOAD = 'ADD_UPLOAD',
  REMOVE_UPLOAD = 'REMOVE_UPLOAD',
}

const addUpload = (id: string) => ({
  type: ACTION_TYPE.ADD_UPLOAD,
  id,
})

const removeUpload = (id: string) => ({
  type: ACTION_TYPE.REMOVE_UPLOAD,
  id,
})

const actions = {
  addUpload,
  removeUpload,
} as const

export type TUploadActions =
  | ActionCreator<typeof addUpload, ACTION_TYPE.ADD_UPLOAD>
  | ActionCreator<typeof removeUpload, ACTION_TYPE.REMOVE_UPLOAD>

export default actions
