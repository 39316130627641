import { ActionCreator } from './type-helpers'

export const enum ACTION_TYPE {
  UNDO = 'UNDO',
  REDO = 'REDO',
  RESET_UNDO_STACK = 'RESET_UNDO_STACK',
  SET_UNDO_ACTION = 'SET_UNDO_ACTION',
}

const undo = (board: string, action: string) => ({
  type: ACTION_TYPE.UNDO,
  board,
  action,
})

const redo = (board: string, action: string) => ({
  type: ACTION_TYPE.REDO,
  board,
  action,
})

const resetUndoStack = () => ({
  type: ACTION_TYPE.RESET_UNDO_STACK,
})

const setUndoAction = (boardId: string, content: unknown) => ({
  type: ACTION_TYPE.SET_UNDO_ACTION,
  boardId,
  content,
})

const actions = {
  undo,
  redo,
  resetUndoStack,
  setUndoAction,
} as const

export type TUndoRedoActions =
  | ActionCreator<typeof undo, ACTION_TYPE.UNDO>
  | ActionCreator<typeof redo, ACTION_TYPE.REDO>
  | ActionCreator<typeof resetUndoStack, ACTION_TYPE.RESET_UNDO_STACK>
  | ActionCreator<typeof setUndoAction, ACTION_TYPE.SET_UNDO_ACTION>

export default actions
