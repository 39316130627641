import useBoard from '@hooks/useBoard'
import { getRootFolderId } from '@helpers/NodeIdGenerator'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'

import { NodeType } from '../../../models'

import { BoardMenuItem } from '../models/boardMenuItem'
import useSelectMenuItem from './useSelectMenuItem'
import { useBoardsMenuTranslations } from './useBoardsMenuTranslations'

interface Props {
  boardId: string
  isBoardSelected: boolean
  selectedFolderId?: string
  isFreeUserMenu?: boolean
}

interface INode {
  id: string
  text: string
  type: string
}

interface IFolder {
  id: string
  icon: string
  title: string
  content: INode[]
  isActive?: boolean
}

const useBoardMenu = ({
  boardId,
  isBoardSelected,
  selectedFolderId,
  isFreeUserMenu,
}: Props) => {
  const rootFolderId = getRootFolderId(boardId)
  const selectMenuItem = useSelectMenuItem()
  const { parseTitle } = useBoardsMenuTranslations()
  const {
    board,
    folders,
    isShared,
    isActive,
    limitReached,
    isPageBoard,
    isOwnBoard,
    onActivate,
    roleText,
    totalBoardMembersCount,
  } = useBoard({ boardId, isFreeUserMenu })

  const selectChildForAllParents = (
    parentRefToSelect: Partial<BoardMenuItem>,
  ) => {
    /* eslint-disable */
    parentRefToSelect.isExpanded = true
    parentRefToSelect.hasSelectedChildren = true
    /* eslint-enable */

    if (parentRefToSelect.parent) {
      selectChildForAllParents(parentRefToSelect.parent)
    }
  }

  const buildMenuItem = (
    { id, icon, title }: IFolder,
    parentRef?: Partial<BoardMenuItem>,
  ): BoardMenuItem => {
    const folder = folders[id]
    const subFolders = folder
      ? folder.content.filter((node) => node.type === NodeType.FOLDER)
      : []

    const item = {
      id,
      icon,
      countBoardMembers: totalBoardMembersCount,
      title: parseTitle(title, board?.rootFolderId === id),
      boardId,
      isSelected: isBoardSelected && id === selectedFolderId,
      isExpanded: false,
      isActive,
      limitReached, // TODO NEXT
      isPageBoard,
      subMenus: [],
      hasSubMenus: subFolders.length > 0,
      hasSharedIcon: rootFolderId === id && (!isOwnBoard || isShared),
      isSponsored: Boolean(board?.options.notCountedTowardsLimit),
      roleText,
      parent: parentRef,
      onClick: () => !isBoardSelected && selectMenuItem(),
      onActivate: !isActive
        ? () => {
            if (isFreeUserMenu) {
              const source = isOwnBoard
                ? PaywallSourceEnum.ACTIVATE_BOARD_LIST
                : PaywallSourceEnum.ACTIVATE_SHARED_BOARD_LIST
              onActivate(source)
              return
            }

            onActivate(PaywallSourceEnum.ACTIVATE_BOARD_LIST)
          }
        : undefined,
    } as BoardMenuItem

    if (parentRef && item.isSelected) {
      selectChildForAllParents(parentRef)
    }

    item.subMenus = subFolders
      .filter((n) => n.type === NodeType.FOLDER && folders[n.id] !== undefined)
      .map((n) => buildMenuItem(folders[n.id], item))

    return item as BoardMenuItem
  }

  try {
    if (board) {
      const root = {
        id: board.rootFolderId,
        icon: board.icon,
        title: board.title,
      } as IFolder
      const menu = buildMenuItem(root, undefined)

      return { menu }
    }

    return { menu: undefined }
  } catch (error) {
    return { menu: undefined }
  }
}

export default useBoardMenu
